import React, { useState, useEffect } from "react";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { FiPlus, FiEdit2, FiArchive, FiCheck, FiX } from "react-icons/fi";
import { RiUnpinLine, RiPushpinLine } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NewConversationActions = ({
  conversationId,
  onNewConversation,
  setReloadConversations,
  setConversationId,
  isMobile,
  isTablet,
  pinnedConversations,
  unpinnedConversations,
}) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [isPinned, setIsPinned] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("New Conversation");


  // Update current conversation details when conversationId or conversations change
  useEffect(() => {
    console.log("Updating current conversation. ID:", conversationId); 
    const pinned = Array.isArray(pinnedConversations) ? pinnedConversations : [];
    const unpinned = Array.isArray(unpinnedConversations) ? unpinnedConversations : [];
    const allConversations = [...pinned, ...unpinned];
    
    if (conversationId && allConversations.length > 0) {
      const currentConversation = allConversations.find(
        (conv) => conv.sessionId === conversationId
      );
      if (currentConversation) {
        console.log("Found current conversation:", currentConversation);
        setCurrentTitle(currentConversation.title || "New Conversation");
        setIsPinned(currentConversation.pinned);
      } else {
        console.log("Current conversation not found in the list");
        setCurrentTitle("New Conversation");
        setIsPinned(false);
      }
    } else {
      console.log("No conversation ID or empty conversations list");
      setCurrentTitle("New Conversation");
      setIsPinned(false);
    }
  }, [conversationId, pinnedConversations, unpinnedConversations]);


  // Rename conversation
  const handleRename = async () => {
    if (newTitle.trim() && conversationId) {
      console.log("Renaming conversation to:", newTitle.trim());
      try {
        const response = await fetch(
          `${backendUrl}/zep/conversations/${conversationId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ newTitle: newTitle.trim() }),
          }
        );
        if (response.ok) {
          console.log("Conversation renamed successfully");
          setCurrentTitle(newTitle.trim());
          setReloadConversations(true);
        } else {
          console.error("Failed to rename conversation");
        }
      } catch (error) {
        console.error("Error renaming conversation:", error.message);
      }
      setIsRenaming(false);
      setNewTitle("");
    }
  };

  // Handle key press for renaming
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  // Archive conversation
  const handleArchive = async () => {
    if (conversationId) {
      try {
        const response = await fetch(
          `${backendUrl}/zep/conversations/${conversationId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          onNewConversation();
          setReloadConversations(true);
        } else {
          console.error("Failed to archive conversation");
        }
      } catch (error) {
        console.error("Error archiving conversation:", error.message);
      }
    }
  };

  // Pin/unpin conversation
  const handlePin = async () => {
    if (conversationId) {
      try {
        const response = await fetch(
          `${backendUrl}/zep/conversations/${conversationId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ pinned: !isPinned }),
          }
        );
        if (response.ok) {
          setIsPinned(!isPinned);
          setReloadConversations(true);
        } else {
          console.error("Failed to pin/unpin conversation");
        }
      } catch (error) {
        console.error("Error pinning/unpinning conversation:", error.message);
      }
    }
  };

  // {`flex items-center ${isMobile || isTablet ? "ml-12" : ""}`}

  return (
    <div className="flex items-start w-full"> 
      <Menu as="div" className="relative inline-block text-left flex-grow max-w-full">
        {({ open }) => (
          <>
          <MenuButton className="px-2 sm:px-3 py-1 sm:py-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex items-center justify-between w-120"> {/* Set fixed width */}
          <span className="mr-1 sm:mr-2 text-sm font-medium truncate max-w-full"> {/* Ensure truncation */}
                  {currentTitle }
                </span>
                <motion.div
                  animate={{ rotate: open ? 180 : 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex-shrink-0"
                >
                  <IoIosArrowDown className="w-3 h-3 sm:w-4 sm:h-4" />
                </motion.div>
              </MenuButton>


            <AnimatePresence>
              {open && (
                <MenuItems
                  as={motion.div}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className="absolute left-0 mt-2 w-56 origin-top-left bg-gray-50 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div className="px-1 py-1">
                    <MenuItem>
                      {({ className }) => (
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className={`${
                            className
                              ? "bg-primary text-white"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm transition-all duration-150 ease-in-out`}
                          onClick={onNewConversation}
                        >
                          <FiPlus className="mr-2 h-5 w-5" aria-hidden="true" />
                          New Conversation
                        </motion.button>
                      )}
                    </MenuItem>
                  </div>
                  {conversationId && (
                    <div className="px-1 py-1">
                      <MenuItem>
                        {({ className }) => (
                          <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`${
                              className
                                ? "bg-primary text-white"
                                : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm transition-all duration-150 ease-in-out`}
                            onClick={() => setIsRenaming(true)}
                          >
                            <FiEdit2
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Rename
                          </motion.button>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ className }) => (
                          <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`${
                              className
                                ? "bg-primary text-white"
                                : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm transition-all duration-150 ease-in-out`}
                            onClick={handlePin}
                          >
                            {isPinned ? (
                              <RiUnpinLine
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <RiPushpinLine
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            {isPinned ? "Unpin" : "Pin"}
                          </motion.button>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ className }) => (
                          <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`${
                              className
                                ? "bg-primary text-white"
                                : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm transition-all duration-150 ease-in-out`}
                            onClick={handleArchive}
                          >
                            <FiArchive
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Archive
                          </motion.button>
                        )}
                      </MenuItem>
                    </div>
                  )}
                </MenuItems>
              )}
            </AnimatePresence>
          </>
        )}
      </Menu>

      <AnimatePresence>
        {isRenaming && (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="flex items-center ml-1 sm:ml-2 flex-shrink-0"
          >
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Enter new title"
              className="border rounded px-1 sm:px-2 py-1 mr-1 sm:mr-2 w-full max-w-[100px] sm:max-w-[150px] focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleRename}
              className="text-green-500 hover:text-green-600 transition-colors duration-200 mr-1"
              aria-label="Confirm rename"
            >
              <FiCheck className="h-4 w-4 sm:h-5 sm:w-5" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsRenaming(false)}
              className="text-red-500 hover:text-red-600 transition-colors duration-200"
              aria-label="Cancel rename"
            >
              <FiX className="h-4 w-4 sm:h-5 sm:w-5" />
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NewConversationActions;