import React, { createContext, useState, useContext, useEffect } from 'react';
import { getSupabase } from '../utils/supabase';
import { isUserInDatabase, handleSlackIntegration, addUser } from '../utils/utils';

const AuthContext = createContext();
const supabase = getSupabase();
const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.user) {
      setUser(session.user);
    }
    setLoading(false);
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  const signUp = async (fullName, email, password) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          full_name: fullName
        }
      }
    });
    if (error) throw error;

    const user =  {
      id: data.user.id,
      email: data.user.email,
      fullName: fullName,
    }

    const userInDatabase = await isUserInDatabase(user);

    if(!userInDatabase){
      await addUser(user);
    };

    const slackUserId = localStorage.getItem('slack_user_id');
    if (slackUserId) handleSlackIntegration(user.id, slackUserId);

    setUser(data.user);
    return user;
  };

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    const slackUserId = localStorage.getItem('slack_user_id');
    if (slackUserId) handleSlackIntegration(data.user.id, slackUserId);

    setUser(data.user);
  };

  const signInWithGoogle = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 0)); // Ensure the component is updated 

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${frontendUrl}/oauthcallback` 
        }
      });
    
      if (error) throw error;

      // Check if the user has a user_prompt
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('user_prompt')
        .eq('id', data.user.id)
        .single();

        if (userError) throw userError;

        // if user_prompt is null, redirect to welcome page
        if (!userData.user_prompt) {
          window.location.href = '/welcome';
        }
      
  
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signOut,
    signUp,
    signIn,
    signInWithGoogle,
    setUser, 
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};