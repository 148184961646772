import React from 'react';
import { Progress } from 'flowbite-react';

const OnboardingProgress = ({ steps, currentStep }) => {
  return (
    <div className="mb-6">
      <Progress progress={(currentStep / steps.length) * 100} />
      <div className="mt-2 flex justify-between">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`text-sm ${
              index < currentStep ? 'text-green-500' : index === currentStep ? 'text-blue-500 font-bold' : 'text-gray-400'
            }`}
          >
            {step.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnboardingProgress;