import React, { useState, useEffect, memo, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { NavSession, ConversationItem } from "./NavSession";
import NewConversationButton from "./NewConversationButton";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "flowbite-react";
import {
  HiChartPie,
  HiViewBoards,
  HiInbox,
  HiArrowSmRight,
  HiQuestionMarkCircle,
  HiOutlineMinusSm,
  HiOutlinePlusSm,
  HiTemplate,
  HiUser,
} from "react-icons/hi";
import { RiToolsFill } from "react-icons/ri";
import { twMerge } from "tailwind-merge";
import "./NavContent.css";
import NavbarLogo from "./NavbarLogo";
//import { Scrollbars } from "react-custom-scrollbars-2";
//import GuidedTour from "./GuidedTour";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NavContent = memo(function NavContent({
  setConversationId,
  reloadConversations,
  setReloadConversations,
  closeSidebar,
  setChatLog,
  isOpen,
  isMobile,
  isTablet,
}) {
  const { signOut } = useAuth();

  const navigate = useNavigate();

  const [pinnedConversations, setPinnedConversations] = useState([]);
  const [unpinnedConversations, setUnpinnedConversations] = useState([]);
  const [menuOpen, setMenuOpen] = useState(null);
  const [renamingId, setRenamingId] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [isToolsMenuOpen, setIsToolsMenuOpen] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);

  const toolsContentRef = useRef(null);


  // function to handle tools menu toggle
  const handleToolsMenuTogle = (open) => {
    setIsToolsMenuOpen(open);
  };

  // Effect to adjust max-height when the menu opens
  useEffect(() => {
    if (isToolsMenuOpen && toolsContentRef.current) {
      toolsContentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isToolsMenuOpen]);

  const handleLogOut = async (e) => {
    e.preventDefault();
    await signOut();
    navigate("/auth");
  };

  const handleCloseSidebar = () => {
    if (isMobile || isTablet) {
      closeSidebar();
    }
  };

  const handleNewConversation = () => {
    setConversationId(null);
    setSelectedConversationId(null);
    setChatLog([]);
    navigate("/", { replace: true });
    handleCloseSidebar();
  };

  const handleConversationClick = (sessionId) => {
    // Prevent reloading if the conversation is already selected
  if (sessionId === selectedConversationId) {
    return;
  }
    setConversationId(sessionId);
    setSelectedConversationId(sessionId);
    setChatLog([]);
    navigate("/", { replace: true });
    handleCloseSidebar();
  };

  const handlePinnedRename = async (sessionId, title) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${sessionId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newTitle: title }),
        }
      );
      if (response.ok) {
        setReloadConversations(true);
      } else {
        console.error("Failed to rename conversation");
      }
    } catch (error) {
      console.error("Error renaming conversation:", error.message);
    }
    setRenamingId(null);
    setNewTitle("");
  };

  const handlePinnedDelete = async (sessionId) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${sessionId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setReloadConversations(true);
      } else {
        console.error("Failed to delete conversation");
      }
    } catch (error) {
      console.error("Error deleting conversation:", error.message);
    }
  };

  const handlePinnedUnpin = async (sessionId) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${sessionId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pinned: false }),
        }
      );
      if (response.ok) {
        setReloadConversations(true);
      } else {
        console.error("Failed to unpin conversation");
      }
    } catch (error) {
      console.error("Error unpinning conversation:", error.message);
    }
  };
  

  return (
    <div className={`h-screen flex flex-col transition-all duration-300 ease-in-out ${isOpen ? 'w-64' : 'w-0'}`}>
      <Sidebar aria-label="Default sidebar" className="h-screen flex flex-col">
      <div className="flex flex-col h-full">

          {/*  logo and new conversation button */}
          <div className="flex-shrink-0 flex items-center justify-between p-2 mt-4 mx-3 z-10 sticky top-0 bg-transparent">
            <div className="w-4/5 max-w-[200px]">
              <NavbarLogo size="responsive" />
            </div>
            <NewConversationButton
              onClick={handleNewConversation}
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>

          <hr className="flex-shrink-0 border-t border-gray-200 my-2" />

          {/* Scrollable content area */}
          <div className="flex-grow overflow-y-auto min-h-0">
          <div className="h-full pr-2 pb-20">
                <Sidebar.Items>
                  <Sidebar.ItemGroup>
                    {/* Pinned conversations section */}
                    <h3 className="text-xs font-semibold text-brandColor mb-2 px-2">
                      PINNED
                    </h3>
                    {pinnedConversations.length > 0 && (
                      <div className="space-y-1 mb-4">
                        {pinnedConversations.map((conversation) => (
                          <ConversationItem
                            key={conversation.sessionId}
                            conversation={conversation}
                            handleConversationClick={() => handleConversationClick(conversation.sessionId)}
                            handleMenuToggle={() => setMenuOpen(conversation.sessionId)}
                            handleRename={handlePinnedRename}
                            handleDelete={handlePinnedDelete}
                            handlePin={handlePinnedUnpin}
                            renamingId={renamingId}
                            setRenamingId={setRenamingId}
                            menuOpen={menuOpen}
                            setMenuOpen={setMenuOpen}
                            newTitle={newTitle}
                            setNewTitle={setNewTitle}
                            isPinned={true}
                            isSelected={selectedConversationId === conversation.sessionId}
                          />
                        ))}
                      </div>
                    )}

                    <hr className="border-t border-gray-200 my-2" />

                    {/* Unpinned conversations section */}
                    <h3 className="text-xs font-semibold text-brandColor mb-2 px-2">
                    CHATS
                    </h3>
                    <NavSession
                      setConversationId={setConversationId}
                      reloadConversations={reloadConversations}
                      setReloadConversations={setReloadConversations}
                      closeSidebar={handleCloseSidebar}
                      setChatLog={setChatLog}
                      setPinnedConversations={setPinnedConversations}
                      setUnpinnedConversations={setUnpinnedConversations}
                      menuOpen={menuOpen}
                      setMenuOpen={setMenuOpen}
                      renamingId={renamingId}
                      setRenamingId={setRenamingId}
                      newTitle={newTitle}
                      setNewTitle={setNewTitle}
                      selectedConversationId={selectedConversationId}
                      setSelectedConversationId={setSelectedConversationId}
                    />

                    <hr className="border-t border-gray-200 my-2" />

                    {/* Tools menu */}

                    <div className="sticky bottom-0 bg-gray-50 z-10">
                      <Sidebar.Collapse
                        icon={RiToolsFill}
                        label="Tools"
                        renderChevronIcon={(theme, open) => {
                          const IconComponent = open ? HiOutlineMinusSm : HiOutlinePlusSm;

                          return (
                            <IconComponent
                              aria-hidden
                              className={twMerge(
                                theme.label.icon.open[open ? "on" : "off"],
                                "tools-menu-icon" 
                              )}
                            />
                          );
                        }}
                        id="tools-menu"
                        className="text-lg"
                        onToggle={handleToolsMenuTogle}
                      >
                        {/* Tools menu content */}
                        <div
                        ref={toolsContentRef}
                        //className={`overflow-y-auto transition-all duration-300 ease-in-out ${
                          //isToolsMenuOpen ? 'max-h-60' : 'max-h-0'
                          // }`}
                        >
                          {/* Tool items */}
                          <Sidebar.Item
                            href="/upload"
                            icon={HiInbox}
                            onClick={handleCloseSidebar}
                          >
                            Upload File
                          </Sidebar.Item>

                          <Sidebar.Item
                            href="/templates"
                            icon={HiTemplate}
                            onClick={handleCloseSidebar}
                          >
                            Library
                          </Sidebar.Item>

                          <Sidebar.Item
                            href="/integrations"
                            icon={HiViewBoards}
                            onClick={handleCloseSidebar}
                          >
                            Integrations
                          </Sidebar.Item>

                          <Sidebar.Item
                            href="/prompts"
                            icon={HiChartPie}
                            onClick={handleCloseSidebar}
                          >
                            Prompt Planner
                          </Sidebar.Item>
                        </div>
                      </Sidebar.Collapse>
                    </div>

                    
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </div>

              
          </div>

          {/* Footer items */}
          <div className="flex-shrink-0 py-2">
            <Sidebar.ItemGroup>

            <Sidebar.Item
                href="/profile"
                icon={HiUser}
                onClick={handleCloseSidebar}
              >
                Profile
              </Sidebar.Item>

              {/* <Sidebar.Item
                href="/faq"
                icon={HiQuestionMarkCircle}
                onClick={handleCloseSidebar}
              >
                Updates & FAQ
              </Sidebar.Item> */}

              {/* <Sidebar.Item
                href="/auth"
                icon={HiArrowSmRight}
                onClick={(e) => {
                  handleLogOut(e);
                  handleCloseSidebar();
                }}
              >
                Log out
              </Sidebar.Item> */}
              
            </Sidebar.ItemGroup>
          </div>
        </div>
      </Sidebar>
      {/* <GuidedTour /> */}
    </div>
  );
});

export default NavContent;