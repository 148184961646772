import React, { useState, useEffect, useMemo, useCallback } from "react";
import { promptCategories } from "../utils/data";
import productManagementPrompts from "../utils/product-management-prompts.json";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  BookOpenIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import { motion, AnimatePresence } from "framer-motion";

// Memoized PromptItem component
const PromptItem = React.memo(({ prompt, onSelectPrompt }) => (
  <Disclosure as="div" className="border rounded-lg shadow-sm mb-2">
    {({ open }) => (
      <>
        <DisclosureButton className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-gray-800 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 transition duration-300 ease-in-out">
          <span>{prompt.title}</span>
          <ChevronDownIcon
            className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-purple-500 transition-transform duration-300`}
          />
        </DisclosureButton>
        <DisclosurePanel className="px-4 pt-2 pb-2 text-gray-600">
          <p className="text-left mb-2 text-sm leading-relaxed font-semibold">
            {prompt.description}
          </p>
          <button
            onClick={() => onSelectPrompt(prompt.content)}
            className="flex items-center px-2 py-1 text-xs font-semibold text-white bg-brandColor rounded-full hover:bg-darkBrand transition duration-200"
          >
            Use Prompt
          </button>
        </DisclosurePanel>
      </>
    )}
  </Disclosure>
));

function PromptLibrary({ onSelectPrompt }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Debounce search query with a 300ms delay
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  // Memoized filtered prompts
  const filteredPrompts = useMemo(() => {
    let filtered = productManagementPrompts;

    if (selectedCategory !== "All") {
      filtered = filtered.filter(
        (prompt) => prompt.category === selectedCategory
      );
    }

    if (debouncedSearchQuery.length >= 3) {
      const words = debouncedSearchQuery.toLowerCase().split(" ");
      filtered = filtered.filter((prompt) =>
        words.every(
          (word) =>
            prompt.title.toLowerCase().includes(word) ||
            prompt.description.toLowerCase().includes(word) ||
            prompt.exampleQuestions.some((question) =>
              question.toLowerCase().includes(word)
            )
        )
      );
    }

    return filtered.sort((a, b) => a.title.localeCompare(b.title));
  }, [debouncedSearchQuery, selectedCategory]);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleCategoryChange = useCallback((event) => {
    setSelectedCategory(event.target.value);
  }, []);

  if (!productManagementPrompts.length) {
    return (
      <div className="text-center p-4 text-gray-500 bg-white shadow-lg rounded-lg">
        <BookOpenIcon className="w-8 h-8 mx-auto mb-2 text-yellow-400" />
        <p>No prompts available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg mt-4">
      <div className="text-xl font-semibold text-darkBrand flex items-center justify-center w-full mb-2">
        <LightBulbIcon className="w-6 h-6 text-brandColor mr-2" />
        Prompt Library
      </div>

      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search prompts..."
          value={searchQuery}
          onChange={handleSearch}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        <SearchIcon className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" />
      </div>

      <div className="mb-4">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          {promptCategories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <AnimatePresence>
        {filteredPrompts.map((prompt) => (
          <motion.div
            key={prompt.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <PromptItem prompt={prompt} onSelectPrompt={onSelectPrompt} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export default React.memo(PromptLibrary);