import React from "react";
import { motion } from "framer-motion";
import { FaSpinner } from "react-icons/fa";

const Loading = ({ message = "Loading..." }) => {
  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 backdrop-blur-sm z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-xl p-8 flex flex-col items-center"
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="text-4xl text-indigo-600 mb-4"
        >
          <FaSpinner />
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-gray-700 font-semibold text-lg"
        >
          {message}
        </motion.p>
      </motion.div>
    </div>
  );
};

export default Loading;
