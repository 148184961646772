import React from "react";
import { Link } from "react-router-dom";
import { HiHome } from "react-icons/hi";

function BackToHomeBtn() {
  return (
    <Link to="/" className="absolute top-4 left-4 sm:top-5 sm:left-5">
      <button
        className="flex justify-center items-center px-2 py-1 sm:px-3 sm:py-1.5 text-xs sm:text-sm rounded-full text-white bg-gradient-to-r from-brandColor to-darkBrand hover:from-darkBrand hover:to-brandColor transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg border-2 border-lightBrand group" >
        <span className="font-medium group-hover:font-semibold">Back</span>
        <HiHome className="ml-1 sm:ml-1.5 h-3 w-3 sm:h-4 sm:w-4 transition-transform duration-300 group-hover:rotate-12" />
      </button>
    </Link>
  );
}

export default BackToHomeBtn;
