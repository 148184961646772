import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Toaster } from "react-hot-toast"
import { PromptProvider } from "./context/PromptContext";
import { BetaFeaturesProvider } from "./context/BetaFeaturesContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BetaFeaturesProvider>
      <PromptProvider>
      <BrowserRouter>
        <App />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toasOptions={{
            duration: 8000,
          }}
          />
        </BrowserRouter>
      </PromptProvider>
      </BetaFeaturesProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();