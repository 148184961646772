import React, { useState, useEffect, useCallback, useRef } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdPushPin } from "react-icons/md";
import { SiTheconversation } from "react-icons/si";
import { useAuth } from "../context/AuthContext";
import "./NavSession.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NavSession = ({
  setConversationId,
  reloadConversations,
  setReloadConversations,
  closeSidebar,
  setChatLog,
  setPinnedConversations,
  setUnpinnedConversations,
  menuOpen,
  setMenuOpen,
  renamingId,
  setRenamingId,
  newTitle,
  setNewTitle,
  selectedConversationId,
  setSelectedConversationId,
}) => {
  const { user } = useAuth();

  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  //const [selectedConversationId, setSelectedConversationId] = useState(null);

  // Fetch conversations
  const fetchConversations = useCallback(async () => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${user.id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch conversations");
      }
      const data = await response.json();
      const sortedConversations = data.conversations.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setConversations(sortedConversations);
      setError(null);
    } catch (error) {
      console.error("Error fetching conversations:", error.message);
      setError("Failed to fetch conversations");
    }
  }, [user]);

  // Reload conversations
  useEffect(() => {
    if (reloadConversations) {
      fetchConversations();
    }
    setReloadConversations(false);
  }, [reloadConversations, fetchConversations, setReloadConversations]);

  // Separate conversations into pinned and unpinned
  useEffect(() => {
    if (conversations.length > 0) {
      const pinned = conversations.filter(
        (conversation) => conversation.pinned
      );
      const unpinned = conversations.filter(
        (conversation) => !conversation.pinned
      );
      setPinnedConversations(pinned);
      setUnpinnedConversations(unpinned);
      console.log("Pinned conversations set:", pinned);
    }
  }, [conversations, setPinnedConversations, setUnpinnedConversations]);

  // Handle conversation click
  const handleConversationClick = (sessionId) => {
    setConversationId(sessionId);
    setSelectedConversationId(sessionId);
    if (typeof closeSidebar === "function") {
      closeSidebar();
    }
  };

  const handleMenuToggle = (sessionId) => {
    setMenuOpen((prev) => (prev === sessionId ? null : sessionId));
  };

  const handleRename = async (sessionId, title) => {
    if (title) {
      try {
        const response = await fetch(
          `${backendUrl}/zep/conversations/${sessionId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ newTitle: title }),
          }
        );
        if (response.ok) {
          setConversations((prevConversations) =>
            prevConversations.map((conv) =>
              conv.sessionId === sessionId ? { ...conv, title: title } : conv
            )
          );
          setReloadConversations(true);
        } else {
          console.error("Failed to rename conversation");
        }
      } catch (error) {
        console.error("Error renaming conversation:", error.message);
      }
      setRenamingId(null);
      setNewTitle("");
    }
  };

  const handleDelete = async (sessionId) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${sessionId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setReloadConversations(true);
      } else {
        console.error("Failed to delete conversation");
      }
    } catch (error) {
      console.error("Error deleting conversation:", error.message);
    }
    closeSidebar();
  };

  const handlePin = async (sessionId, pin) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/conversations/${sessionId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pinned: pin }),
        }
      );
      if (response.ok) {
        setReloadConversations(true);
      } else {
        console.error("Failed to pin conversation");
      }
    } catch (error) {
      console.error("Error pinning conversation:", error.message);
    }
    closeSidebar();
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {error && <p className="text-red-500 text-center text-sm">{error}</p>}

      <div className="flex-grow overflow-y-auto scrollbar py-1">
        {conversations
          .filter((conv) => !conv.pinned)
          .map((conversation) => (
            <ConversationItem
              key={conversation.sessionId}
              conversation={conversation}
              handleConversationClick={handleConversationClick}
              handleMenuToggle={handleMenuToggle}
              handleRename={handleRename}
              handleDelete={handleDelete}
              handlePin={handlePin}
              closeSidebar={closeSidebar}
              renamingId={renamingId}
              setRenamingId={setRenamingId}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              newTitle={newTitle}
              setNewTitle={setNewTitle}
              isPinned={false}
              isSelected={selectedConversationId === conversation.sessionId}
            />
          ))}
      </div>
    </div>
  );
};

// ConversationItem component
// renders a single conversation item in the navigation
const ConversationItem = ({
  conversation,
  handleConversationClick,
  handleMenuToggle,
  handleRename,
  handleDelete,
  handlePin,
  renamingId,
  setRenamingId,
  menuOpen,
  setMenuOpen,
  newTitle,
  setNewTitle,
  isPinned = false,
  isSelected = false,
}) => {
  const menuRef = useRef(null);

  // const getFullTitle = (text) => text;

  // const truncateText = (text, maxLength) => {
  //   if (text.length <= maxLength) return { displayText: text, fullText: text };
  //   return {
  //     displayText: text.substr(0, maxLength) + "...",
  //     fullText: text,
  //   };
  // };

  const maxLength = 30; // max length of the conversation title in the navigation
  const defaultTitle = "Onboarding Conversation"; // Default title for conversations without a title

  // Function to get the display title
  const getDisplayTitle = (title) => {
    // Check for "no title" or empty/whitespace title
    let displayTitle =
      !title || title.trim() === "" || title.toLowerCase() === "no title"
        ? defaultTitle
        : title.trim();

    if (displayTitle.length > maxLength) {
      displayTitle = displayTitle.substr(0, maxLength) + "...";
    }

    return displayTitle;
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(null);
    }
  };

  useEffect(() => {
    if (menuOpen === conversation.sessionId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen, conversation.sessionId]);

  return (
    <div className={`conversation-item ${isSelected ? "selected" : ""}`}>
      {isPinned ? (
        <MdPushPin className="hidden text-gray-500 text-sm mr-1" />
      ) : (
        <SiTheconversation className="hidden text-gray-500 text-sm mr-1" />
      )}
      {renamingId === conversation.sessionId ? (
        <input
          className="flex-1 px-2 py-1 text-sm border border-gray-300 rounded"
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onBlur={() => handleRename(conversation.sessionId, newTitle)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRename(conversation.sessionId, newTitle);
            }
          }}
          autoFocus
        />
      ) : (
        <button
          className="flex-1 text-left py-1 text-sm text-gray-700 hover:text-gray-900 transition-colors truncate"
          onClick={() => handleConversationClick(conversation.sessionId)}
          title={getDisplayTitle(conversation.title)}
        >
          {getDisplayTitle(conversation.title)}
        </button>
      )}
      <FiMoreHorizontal
        className="cursor-pointer text-gray-400 hover:text-gray-600 transition-colors ml-2"
        onClick={() => handleMenuToggle(conversation.sessionId)}
      />
      {menuOpen === conversation.sessionId &&
        renamingId !== conversation.sessionId && (
          <div
            ref={menuRef}
            className="absolute right-0 mt-2 bg-white border border-gray-200 rounded shadow-md z-50"
            style={{ top: "-10px" }}
          >
            <button
              className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 transition-colors"
              onClick={() => {
                setRenamingId(conversation.sessionId);
                setNewTitle("");
                setMenuOpen(null);
              }}
            >
              Rename
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 transition-colors"
              onClick={() => {
                handleDelete(conversation.sessionId);
                setMenuOpen(null);
              }}
            >
              Archive
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 transition-colors"
              onClick={() => {
                handlePin(conversation.sessionId, !conversation.pinned);
                setMenuOpen(null);
              }}
            >
              {conversation.pinned ? "Unpin" : "Pin"}
            </button>
          </div>
        )}
    </div>
  );
};

export { NavSession, ConversationItem };