import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './MarkdownRenderer.css';

const MarkdownRenderer = ({ content }) => {
  const components = {
    ul: ({ children }) => <ul className="list-disc pl-2">{children}</ul>,
    ol: ({ children }) => <ol className="list-decimal pl-4">{children}</ol>, 
    li: ({ children }) => <li className="mb-2">{children}</li>,
    h1: ({ children }) => <h1 className="text-3xl font-bold my-4">{children}</h1>,
    h2: ({ children }) => <h2 className="text-2xl font-semibold my-3">{children}</h2>,
    h3: ({ children }) => <h3 className="text-xl font-semibold my-2">{children}</h3>,
    h4: ({ children }) => <h4 className="text-lg font-semibold my-2">{children}</h4>,
    h5: ({ children }) => <h5 className="text-base font-semibold my-1">{children}</h5>,
    h6: ({ children }) => <h6 className="text-sm font-semibold my-1">{children}</h6>,
    table: ({ node, ...props }) => (
      <div className="overflow-x-auto max-w-full my-5">
        <table
          className="w-full bg-white rounded-lg overflow-hidden shadow-md border border-gray-200" 
          {...props}
        />
      </div>
    ),
    th: ({ node, ...props }) => (
      <th
        className="bg-gray-100 text-gray-700 font-bold py-3 px-4 text-left border-b border-r border-gray-200"
        {...props}
      />
    ),
    td: ({ node, children, ...props }) => (
      <td className="py-3 px-4 border-b border-r border-gray-200 text-gray-700" {...props}>
        {React.Children.map(children, child => 
          typeof child === 'string' ? <span dangerouslySetInnerHTML={{ __html: child }} /> : child
        )}
      </td>
    ),
    code: ({ node, inline, className, children, ...props }) => {
      return !inline ? (
        <div className="markdown-code-block bg-gray-800 rounded-2xl p-4 text-base leading-relaxed mb-5 shadow-lg text-white relative max-w-full">
          <pre className="overflow-x-auto whitespace-pre">
            <code className={className} {...props}>
              {children}
            </code>
          </pre>
        </div>
      ) : (
        <code className={`markdown-inline-code bg-gray-200 rounded px-1.5 py-0.5 text-sm font-mono text-gray-800 ${className}`} {...props}>
          {children}
        </code>
      );
    },
    p: ({ children }) => <p className="whitespace-pre-line">{children}</p> ,
    a: ({ children, href }) => (
      <a 
        href={href} 
        className="text-blue-500 hover:underline" 
        target="_blank" 
        rel="noopener noreferrer" 
      >
        {children}
      </a>
    ),
  };

  return (
    <div className="messageText"> 
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        components={components}
      />
    </div>
  );
};

export default MarkdownRenderer;