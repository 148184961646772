import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ConfettiEffect = ({ show }) => {

  const { width, height } = useWindowSize();
  const [pieces, setPieces] = useState(0);

  useEffect(() => {
    if (show) {
      setPieces(300); // Number of confetti pieces
      setTimeout(() => setPieces(0), 5000); // Stop the confetti after 5 seconds
    }
  }, [show]);

  return (
    show && (
      <Confetti
        width={width}
      height={height}
      numberOfPieces={pieces}
      initialVelocityX={{ min: -10, max: 5 }}
      initialVelocityY={{ min: -10, max: 5 }}
      gravity={0.02}
      recycle={false}
      wind={0.01}
      />
    )
  );
};

export default ConfettiEffect;