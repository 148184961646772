export const categoryData = {
  product: {
    keywords: [
      "product",
      "feature",
      "market",
      "pricing",
      "competitor",
      "user experience",
      "roadmap",
      "prototype",
      "MVP",
      "launch",
      "iteration",
      "feedback",
      "user testing",
      "product-market fit",
      "scalability",
      "usability",
      "functionality",
      "design",
      "product lifecycle",
      "value proposition",
    ],
    prompts: [
      "Can you elaborate on the product features?",
      "What's the target market for this product?",
      "How does this product compare to competitors?",
      "What's the pricing strategy for this product?",
      "How can we improve the user experience?",
      "What's on our product roadmap for the next quarter?",
      "When can we expect the MVP to be ready?",
      "How are we incorporating user feedback into the product?",
      "What metrics should we use to measure product success?",
      "How can we ensure product scalability?",
      "What's our strategy for product iterations?",
      "How does our value proposition stand out in the market?",
      "What user testing methods are we employing?",
      "How can we optimize the product launch strategy?",
      "What are the key features for the next release?",
    ],
  },
  okr: {
    keywords: [
      "okr",
      "objective",
      "key result",
      "goal",
      "strategy",
      "alignment",
      "performance",
      "measure",
      "tracking",
      "achievement",
      "progress",
      "quarterly",
      "annual",
      "target",
      "benchmark",
      "milestone",
      "evaluation",
      "review",
      "adjustment",
      "cascade",
    ],
    prompts: [
      "Can you give an example of a good key result?",
      "How often should we review our OKRs?",
      "What's a common mistake in setting OKRs?",
      "How do OKRs align with company strategy?",
      "What's the process for cascading OKRs throughout the organization?",
      "How can we ensure OKRs are challenging yet achievable?",
      "What tools are we using to track OKR progress?",
      "How do we handle OKRs that are not met?",
      "Can you explain the difference between output and outcome OKRs?",
      "How do we balance qualitative and quantitative key results?",
      "What's the best way to communicate OKRs to the team?",
      "How do we align individual OKRs with team and company OKRs?",
      "What's our strategy for mid-quarter OKR adjustments?",
      "How do we celebrate OKR achievements?",
      "What's the ideal number of OKRs for each level of the organization?",
    ],
  },
  project: {
    keywords: [
      "project",
      "timeline",
      "milestone",
      "deadline",
      "team",
      "scope",
      "resources",
      "budget",
      "risk",
      "stakeholder",
      "deliverable",
      "agile",
      "waterfall",
      "sprint",
      "backlog",
      "dependency",
      "status report",
      "project manager",
      "collaboration",
      "GANTT",
    ],
    prompts: [
      "What are the key milestones for this project?",
      "How can we optimize the project timeline?",
      "What potential risks should we consider?",
      "How are we tracking project progress?",
      "Who are the key stakeholders for this project?",
      "What's our strategy for managing project dependencies?",
      "How are we handling resource allocation?",
      "What's the communication plan for this project?",
      "How do we ensure project scope doesn't creep?",
      "What methodology are we using for this project (e.g., Agile, Waterfall)?",
      "How often do we plan to have status meetings?",
      "What tools are we using for project management?",
      "How are we prioritizing tasks in the project backlog?",
      "What's our contingency plan for missed deadlines?",
      "How are we measuring the success of this project?",
    ],
  },
  finance: {
    keywords: [
      "budget",
      "cost",
      "revenue",
      "profit",
      "investment",
      "ROI",
      "cash flow",
      "forecast",
      "financial report",
      "balance sheet",
      "income statement",
      "audit",
      "tax",
      "funding",
      "expense",
      "asset",
      "liability",
      "equity",
      "depreciation",
      "valuation",
    ],
    prompts: [
      "What's our current budget allocation?",
      "How can we improve our profit margins?",
      "What are our main revenue streams?",
      "Are there any cost-saving opportunities?",
      "What's our financial forecast for the next fiscal year?",
      "How does our cash flow look for the upcoming quarter?",
      "What's our strategy for securing additional funding?",
      "Can you explain our current ROI on major investments?",
      "What financial metrics should we focus on improving?",
      "How are we managing our debt-to-equity ratio?",
      "What's our plan for the upcoming tax season?",
      "How often do we conduct internal financial audits?",
      "What's our strategy for asset depreciation?",
      "How do we handle foreign currency risk?",
      "What's our approach to financial risk management?",
    ],
  },
  marketing: {
    keywords: [
      "campaign",
      "audience",
      "channel",
      "conversion",
      "branding",
      "SEO",
      "social media",
      "content marketing",
      "lead generation",
      "customer acquisition",
      "analytics",
      "ROI",
      "brand awareness",
      "engagement",
      "email marketing",
      "influencer",
      "viral",
      "funnel",
      "retention",
      "positioning",
    ],
    prompts: [
      "Who is our target audience for this campaign?",
      "Which marketing channels are most effective for us?",
      "How can we improve our conversion rates?",
      "What metrics should we use to measure success?",
      "How can we optimize our content marketing strategy?",
      "What's our plan for improving SEO?",
      "How are we leveraging social media for brand awareness?",
      "What's our customer acquisition cost across different channels?",
      "How can we improve our email marketing open rates?",
      "What's our strategy for influencer partnerships?",
      "How are we using data analytics to inform our marketing decisions?",
      "What's our plan for creating viral content?",
      "How can we optimize our marketing funnel?",
      "What's our customer retention strategy?",
      "How do we measure the ROI of our marketing efforts?",
    ],
  },
  humanResources: {
    keywords: [
      "recruitment",
      "hiring",
      "onboarding",
      "training",
      "performance review",
      "compensation",
      "benefits",
      "employee engagement",
      "retention",
      "diversity",
      "inclusion",
      "workplace culture",
      "talent management",
      "succession planning",
      "HR policy",
      "compliance",
      "employee relations",
      "workforce planning",
      "employee development",
      "remote work",
    ],
    prompts: [
      "What's our strategy for attracting top talent?",
      "How can we improve our onboarding process?",
      "What training programs should we implement?",
      "How often should we conduct performance reviews?",
      "What's our approach to employee compensation and benefits?",
      "How can we improve employee engagement?",
      "What's our strategy for promoting diversity and inclusion?",
      "How are we fostering a positive workplace culture?",
      "What's our succession planning strategy?",
      "How do we ensure compliance with labor laws?",
      "What's our policy on remote work?",
      "How can we improve employee retention rates?",
      "What tools are we using for HR management?",
      "How do we handle conflict resolution in the workplace?",
      "What's our approach to continuous employee development?",
    ],
  },
};

export const generateDefaultPrompts = () => [
  "Can you provide more details on that?",
  "What are the next steps we should take?",
  "How does this information impact our overall strategy?",
  "Are there any potential challenges we should consider?",
  "Can you summarize the key points discussed so far?",
  "How does this align with our long-term goals?",
  "What resources would we need to implement this?",
  "Who are the key stakeholders we should involve?",
  "What's the potential impact on other departments or projects?",
  "How can we measure the success of this initiative?",
];



export const promptCategories = [
  "All",
  "Discover",
  "Define",
  "Develop",
  "Deliver",
  "Align",
  "Growth"
];

export const categorizedPrompts = {
  Discover: [
    "Prompt for ICP Description",
    "Prompt for Competitive Analysis",
    "Prompt for Unique Value Proposition Canvas",
    "Prompt for Jobs to be Done (JTBD)",
    "Prompt for Customer Obsession Exercise"
  ],
  Define: [
    "Prompt for Business Model Canvas",
    "Prompt for Product Strategy",
    "Prompt for OKRs",
    "Prompt for Product Brief",
    "Prompt for Product Vision Board",
    "Prompt for Working Backwards - Press Release"
  ],
  Develop: [
    "Prompt for Feature Prioritization Matrix",
    "Prompt for Releases and Release Notes",
    "Prompt for Minimum Viable Product (MVP) Definition"
  ],
  Deliver: [
    "Prompt for Go-To-Market Strategy",
    "Prompt for Product Launch Plan",
    "Prompt for Stakeholder Communication Plan"
  ],
  Align: [
    "Prompt for Key Performance Indicators (KPIs)"
  ],
  Growth: [
    "Prompt for Post-Launch Review Document"
  ]
};




export const promptLibrary = [
  {
    id: "product-vision",
    category: "Product Strategy",
    title: "Define Product Vision",
    description: "Craft a clear and inspiring product vision that aligns with business goals.",
    exampleQuestions: [
      "What is the long-term vision for our product?",
      "How does our product vision align with our company's mission?",
      "What key problems does our product solve for users?",
      "How can we make our product vision more inspiring and actionable?",
      "What metrics will indicate we've achieved our product vision?"
    ]
  },
  {
    id: "feature-prioritization",
    category: "Product Strategy",
    title: "Feature Prioritization",
    description: "Prioritize product features based on user value and business impact.",
    exampleQuestions: [
      "What criteria should we use to prioritize our product features?",
      "How can we balance user needs with business objectives in our prioritization?",
      "What methods can we use to gather data for feature prioritization?",
      "How should we communicate our prioritization decisions to stakeholders?",
      "How often should we review and adjust our feature priorities?"
    ]
  },
  {
    id: "project-planning",
    category: "Project Management",
    title: "Project Planning and Scheduling",
    description: "Create comprehensive project plans and schedules.",
    exampleQuestions: [
      "What are the key milestones for this project?",
      "How can we optimize our project timeline?",
      "What dependencies exist between different project tasks?",
      "How should we allocate resources across the project timeline?",
      "What risks should we consider in our project planning?"
    ]
  },
  {
    id: "okr-setting",
    category: "OKR Management",
    title: "Setting Effective OKRs",
    description: "Develop impactful Objectives and Key Results for your team or organization.",
    exampleQuestions: [
      "How can we align our OKRs with our overall product strategy?",
      "What makes a good objective for our product team?",
      "How can we ensure our key results are measurable and impactful?",
      "How often should we review and update our OKRs?",
      "How can we cascade OKRs effectively throughout the organization?"
    ]
  },
  {
    id: "user-research",
    category: "User Experience",
    title: "Conducting User Research",
    description: "Plan and execute effective user research to inform product decisions.",
    exampleQuestions: [
      "What user research methods are most appropriate for our current product phase?",
      "How can we ensure we're getting representative user feedback?",
      "What questions should we ask in our user interviews?",
      "How can we translate user research findings into actionable insights?",
      "How often should we conduct user research?"
    ]
  },
  {
    id: "agile-implementation",
    category: "Agile Methodologies",
    title: "Implementing Agile Practices",
    description: "Adopt and optimize Agile methodologies in your product development process.",
    exampleQuestions: [
      "How can we effectively implement Scrum in our product development process?",
      "What strategies can we use to improve our sprint planning?",
      "How can we make our daily stand-ups more effective?",
      "What metrics should we track to measure the success of our Agile implementation?",
      "How can we balance Agile flexibility with long-term planning?"
    ]
  },
  {
    id: "market-analysis",
    category: "Market Analysis",
    title: "Conducting Market Analysis",
    description: "Analyze market trends and competitive landscape to inform product strategy.",
    exampleQuestions: [
      "Who are our main competitors and what are their strengths and weaknesses?",
      "What are the current trends in our target market?",
      "How can we differentiate our product in the current market?",
      "What potential market opportunities should we explore?",
      "How can we use market analysis to inform our product roadmap?"
    ]
  },
  {
    id: "stakeholder-management",
    category: "Stakeholder Management",
    title: "Managing Stakeholders",
    description: "Effectively engage and communicate with project stakeholders.",
    exampleQuestions: [
      "Who are the key stakeholders for our product/project?",
      "How can we better communicate project progress to stakeholders?",
      "What strategies can we use to manage conflicting stakeholder interests?",
      "How often should we update different stakeholder groups?",
      "How can we ensure stakeholder buy-in for major product decisions?"
    ]
  }
];