
import React from "react";
//import { HiMenu, HiX } from "react-icons/hi";
import { BsLayoutSidebar } from 'react-icons/bs';
import { motion } from "framer-motion";

const LeftMenuButton = ({ isOpen, toggleSidebar }) => {
  return (
    <motion.button
      className="fixed top-4 left-4 text-2xl text-primary cursor-pointer p-1 z-50 left-menu-button"
      onClick={toggleSidebar}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
    >
      <BsLayoutSidebar 
        className={`text-brandColor hover:text-darkBrand text-2xl sm:text-3xl ${isOpen ? 'rotate-180' : ''}`} 
        style={{ transition: 'transform 0.3s ease-in-out' }}
      />
    </motion.button>
  );
};

export default LeftMenuButton;
