// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message p {
    margin-bottom: 1rem; 
    line-height: 1.5; /* Adjust the line height within paragraphs */
  }


`, "",{"version":3,"sources":["webpack://./src/components/Onboarding.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB,EAAE,6CAA6C;EACjE","sourcesContent":[".chat-message p {\n    margin-bottom: 1rem; \n    line-height: 1.5; /* Adjust the line height within paragraphs */\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
