import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from '../../context/AuthContext';
import { getSupabase } from '../../utils/supabase';
import { toast } from "react-hot-toast";

const LoginForm = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");

  const navigate = useNavigate();
  const { signIn, signInWithGoogle } = useAuth();
  const supabase = getSupabase();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signIn(loginEmail, loginPassword);
      toast.success("Logged in successfully!"); 
      navigate("/");
    } catch (error) {
      console.error("Error during login:", error.message);
      setErrorMessage(error.message);
      toast.error(error.message); 
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      toast.success("Logged in with Google successfully!")
    } catch (error) {
      console.error("Error during Google signup:", error.message);
      setErrorMessage(error.message);
      toast.error(error.message); 
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      setResetMessage("Password reset email sent. Check your inbox.");
      toast.success("Password reset email sent. Check your inbox.");
    } catch (error) {
      console.error("Error during password reset:", error.message);
      setResetMessage(error.message);
      toast.error(error.message); 
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-cyan-50 to-lightBrand">
      <div className="max-w-md w-full mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-brandColor text-center mb-6">Welcome Back</h1>
        {!isResetPassword ? (
          <>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <input
                  type="email"
                  name="loginEmail"
                  id="loginEmail"
                  placeholder="Login Email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
                />
              </div>
              <div className="relative mb-4">
                <input
                  type={showLoginPassword ? "text" : "password"}
                  name="loginPassword"
                  id="loginPassword"
                  placeholder="Login Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
                />
                <button
                  type="button"
                  onClick={() => setShowLoginPassword(!showLoginPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showLoginPassword ? (
                    <AiFillEyeInvisible size={24} title="Hide Password" />
                  ) : (
                    <AiFillEye size={24} title="Show Password" />
                  )}
                </button>
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-brandColor text-white rounded-md hover:bg-darkBrand focus:outline-none focus:ring-2 focus:ring-brandColor"
              >
                Continue
              </button>
              {/* {errorMessage && <p className="mt-4 text-red-500 text-sm">{errorMessage}</p>} */}
            </form>

            <div className="flex items-center my-6 w-full max-w-sm">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="mx-4 text-gray-600 text-sm">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            
            <button
              className="w-full flex items-center justify-center gap-4 py-3 border border-gray-300 rounded-md bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-brandColor"
              onClick={handleSignInWithGoogle}
            >
              <FcGoogle size={24} />
              Continue with Google
            </button>

            <div className="mt-4 text-center">
              <button
                onClick={() => setIsResetPassword(true)}
                className="text-brandColor hover:underline"
              >
                Forgot Password?
              </button>
            </div>
          </>
        ) : (
          <form onSubmit={handleResetPassword}>
            <div className="mb-4">
              <input
                type="email"
                name="resetEmail"
                id="resetEmail"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-brandColor text-white rounded-md hover:bg-darkBrand focus:outline-none focus:ring-2 focus:ring-brandColor"
            >
              Reset Password
            </button>
            {resetMessage && (<p className={`mt-4 text-center text-lg p-2 rounded ${
                resetMessage.includes("error") ? "text-red-600 bg-red-100" : "text-brandColor bg-lightBrand"
              }`}>
                {resetMessage}
              </p>
            )}
            <div className="mt-4 text-center">
              <button
                onClick={() => setIsResetPassword(false)}
                className="text-brandColor hover:underline"
              >
                Back to Login
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginForm;