
import { createContext, useContext, useState } from 'react';

const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const [currentPrompt, setCurrentPrompt] = useState(""); // Renamed variable
  
  return (
    <PromptContext.Provider value={{ currentPrompt, setCurrentPrompt }}>
      {children}
    </PromptContext.Provider>
  );
};

export const usePrompt = () => useContext(PromptContext);