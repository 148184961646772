import React from "react";
import { BsLayoutSidebarReverse } from 'react-icons/bs';
import { motion } from "framer-motion";

const RightMenuButton = ({ isOpen, toggleSidebar }) => {
  return (
    <motion.button
      className="fixed top-4 right-4 text-2xl text-primary cursor-pointer p-1 z-50 right-menu-button"
      onClick={toggleSidebar}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
    >
      <BsLayoutSidebarReverse 
        className={`text-brandColor hover:text-darkBrand text-2xl sm:text-3xl ${isOpen ? 'rotate-180' : ''}`} 
        style={{ transition: 'transform 0.3s ease-in-out' }}
      />
    </motion.button>
  );
};

export default RightMenuButton;