import React, { useState, useEffect } from "react";
import SignupForm from "../components/signup/SignUpForm";
import LoginForm from "../components/login/LoginForm";
// import "./Auth.css";
import { motion } from "framer-motion";
import Logo from "../components/Logo";

const Login = () => {
  const [isSignupFormVisible, setIsSignupFormVisible] = useState(false);
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);

  useEffect(() => {
    const slackUserId = new URLSearchParams(window.location.search).get(
      "slack_user_id"
    );
    if (slackUserId) {
      localStorage.setItem("slack_user_id", slackUserId);
    }
  }, []);

  const handleClick = (purpose) => {
    if (purpose === "signup") {
      setIsSignupFormVisible(true);
    }
    if (purpose === "login") {
      setIsLoginFormVisible(true);
    }
  };

  return (
    <>
      {!isSignupFormVisible && !isLoginFormVisible ? (
        <div className="flex justify-center items-center h-screen bg-gradient-to-br from-cyan-50 to-lightBrand">
          <div className="text-center">
            <h1 className="text-7xl font-bold text-brandColor mb-8">
              Welcome{" "}
            </h1>
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                opacity: { duration: 0.8, ease: "easeInOut" },
                y: { duration: 0.8, ease: "easeOut" },
              }}
              className="mb-8"
            >
              <Logo size="large" clickable={false} />
            </motion.div>
            <div className="space-x-4">
              <button
                className="bg-brandColor text-white font-bold py-2 px-4 rounded hover:bg-darkBrand transition duration-300"
                onClick={() => handleClick("login")}
              >
                Log in
              </button>
              <button
                className="bg-brandColor text-white font-bold py-2 px-4 rounded hover:bg-darkBrand transition duration-300"
                onClick={() => handleClick("signup")}
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      ) : isSignupFormVisible ? (
        <SignupForm />
      ) : (
        <LoginForm />
      )}
    </>
  );
};

export default Login;
