import { getSupabase } from '../utils/supabase';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const supabase = getSupabase();


export async function handleSlackIntegration(userId, slackUserId) {
    const { error } = await supabase
      .from('integrations')
      .upsert({
        user_id: userId,
        name: 'Slack',
        data: {
          slack_user_id: slackUserId
        }
      }, { onConflict: 'data->>slack_user_id' });

    if (error) {
      console.error('Error storing Slack integration in Supabase:', error);
      throw error;
    }
}

export async function isUserInDatabase (user) {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.id)

      if (error) throw error;

      if (data.length === 0) {
        return false;
      } else {
        return true;
      }
    
    } catch (error) {
      console.error('Error during user authentication:', error);
    }
}

export async function addUser (user) {
    try {
        const { error: insertError } = await supabase
        .from('users')
        .insert([{ id: user.id, email: user.email }]);

        if (insertError) throw insertError;

        await addUserToZep({ user_id: user.id, email: user.email });

    } catch (error) {
        console.error('Error adding user to database:', error);
    }
}

//
export async function updateUserFullName(supabase, userId) {
  try {
    // fetch user auth data
    const { data: { user}, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;

    // get the full name from user metadata
    const fullName = user.user_metadata.full_name || user.user_metadata.name || 'N/A';

    // update the users table
    const { error: updateError } = await supabase
      .from("users")
      .update({ full_name: fullName })
      .eq("id", userId);

    if (updateError) throw updateError;

    console.log("User full name updated successfully");
  } catch (error) {
    console.error("Error updating user full name:", error);
  }
}



export async function addUserToZep (user) {
    const response = await fetch(`${backendUrl}/zep/addUser`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });
    if (!response.ok) {
        throw new Error("Failed to add user to Zep database");
    }
    return await response.json();
}


// Send data to Pipedream 
export const sendToPipedream = async (data) => {
  const pipedreamWebhookUrl = process.env.REACT_APP_PIPEDREAM_WEBHOOK_URL;
  
  if (!pipedreamWebhookUrl) {
    throw new Error("Pipedream Webhook URL is not set");
  }

  // Add a check for the correct data structure
  if (!data.emailDomain) {
    console.error("Invalid data structure. 'emailDomain' is required.");
    return { success: false, error: "Invalid data structure" };
  }

  console.log("Sending data to Pipedream:", data);

  try {
    const response = await fetch(pipedreamWebhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Failed to send data to Pipedream: ${response.status} ${response.statusText}`);
    }

    const responseText = await response.text();
    console.log("Response from Pipedream:", responseText);

    try {
      const jsonResponse = JSON.parse(responseText);
      return { success: true, data: jsonResponse };
    } catch (jsonError) {
      return { success: true, data: responseText };
    }
  } catch (error) {
    console.error('Error sending data to Pipedream:', error);
    throw error;
  }
};

export const setupResizeObserverErrorHandler = () => {
  const errorHandler = (error) => {
    if (
      error.message === 'ResizeObserver loop completed with undelivered notifications.' ||
      error.message === 'ResizeObserver loop limit exceeded'
    ) {
      const resizeObserverError = document.getElementById('webpack-dev-server-client-overlay-div');
      const resizeObserverError2 = document.getElementById('webpack-dev-server-client-overlay');
      if (resizeObserverError && resizeObserverError.contains(document.activeElement)) {
        resizeObserverError.style.display = 'none';
      }
      if (resizeObserverError2 && resizeObserverError2.contains(document.activeElement)) {
        resizeObserverError2.style.display = 'none';
      }
    }
  };

  window.addEventListener('error', errorHandler);
  window.addEventListener('unhandledrejection', errorHandler);
};