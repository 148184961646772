import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserInDatabase, addUser, handleSlackIntegration } from '../utils/utils';
import { useAuth } from '../context/AuthContext';
import { getSupabase } from '../utils/supabase';

const supabase = getSupabase();

const OAuthCallback = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  useEffect(() => {
    const handleAuthResult = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
  
      if (error) {
        console.error('Error getting session:', error);
        navigate('/auth');
        return;
      }
  
      if (session && session.user) {
        const user = {
          id: session.user.id,
          email: session.user.email,
        };
  
        const userInDatabase = await isUserInDatabase(user);
  
        if (!userInDatabase) {
          await addUser(user);
          navigate('/welcome');
        } else {
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('user_prompt')
            .eq('id', user.id)
            .single();
  
          if (userError) {
            console.error('Error fetching user data:', userError);
            navigate('/auth');
            return;
          }
  
          if (!userData.user_prompt) {
            navigate('/welcome');
          } else {
            navigate('/');
          }
        }
  
        const slackUserId = localStorage.getItem('slack_user_id');
        if (slackUserId) handleSlackIntegration(user.id, slackUserId);
  
        setUser(session.user);
      } else {
        navigate('/auth');
      }
    };
  
    handleAuthResult();
  }, [navigate, setUser]);
  
  return null;
};

export default OAuthCallback;