import React, { useEffect, useRef, useState } from "react";
import Error from "../components/Error";
import Loading from "../components/Loading";
import NavContent from "../components/NavContent";
import { useAuth } from "../context/AuthContext";
import { FiCopy, FiCheck, FiSave } from "react-icons/fi";
import MarkdownRenderer from "../components/MarkdownRenderer";
import socketIOClient from "socket.io-client";
//import PromptSuggestions from "../components/promptSuggestions";
import { Sidebar } from "flowbite-react";
import PromptBar from "../components/PromptBar";
import NextStepsPrompts from "../components/NextStepsPrompts";
import LeftMenuButton from "../components/LeftMenuButton";
import RightMenuButton from "../components/RightMenuButton";
import RightNav from "../components/rightNav";
import { usePrompt } from "../context/PromptContext";
import "./Home.css";
import NewConversationActions from "../components/NewConversationActions";
import { useBetaFeatures } from "../context/BetaFeaturesContext";
import Hero from "../components/Hero";
import { toast } from "react-hot-toast";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Home = () => {
  const { user } = useAuth();

  const leftSidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const chatLogEndRef = useRef(null);

  const { betaFeatures } = useBetaFeatures();

  const { currentPrompt, setCurrentPrompt } = usePrompt();

  const [inputPrompt, setInputPrompt] = useState("");
  const [err, setErr] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [chatLog, setChatLog] = useState([]);
  const [reloadConversations, setReloadConversations] = useState(true);
  const [transcription, setTranscription] = useState("");
  const textareaRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [messageCounter, setMessageCounter] = useState(0);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [showPrompts, setShowPrompts] = useState(true);
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1024
  );
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(!isMobile); // it was false
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(
    !isMobile && !isTablet
  ); // it was false
  const [pinnedConversations, setPinnedConversations] = useState([]);
  const [unpinnedConversations, setUnpinnedConversations] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showHero, setShowHero] = useState(true);
  const [savedMessageIds, setSavedMessageIds] = useState(new Set());


  // useEffect to fetch conversations
  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/zep/conversations/${user.id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch conversations");
        }
        const data = await response.json();
        const sortedConversations = data.conversations.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        const pinned = sortedConversations.filter((conv) => conv.pinned);
        const unpinned = sortedConversations.filter((conv) => !conv.pinned);
        setPinnedConversations(pinned);
        setUnpinnedConversations(unpinned);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    if (user && user.id) {
      fetchConversations();
    }
  }, [user, reloadConversations]);

  // check if there's an AI message in the chat log
  const hasAIMessage = chatLog.some((message) => message.role === "ai");

  const toggleLeftSidebar = () => {
    setIsLeftSidebarOpen((prev) => !prev);
  };

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen((prev) => !prev);
  };

  const copyToClipboard = (content, messageId) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
      })
      .catch((err) => {});
  };

  // save message to pinecone and supabase
  const handleSaveMessage = async (content, messageId) => {
    // Immediately set the message as saved
    setSavedMessageIds(prev => new Set(prev).add(messageId));

    try {
      const response = await fetch(`${backendUrl}/save-message-to-pinecone`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          userId: user.id,
          content: content,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Message saved successfully to Pinecone:", data);
        toast.success("Message saved successfully to knowledge base");
      } else {
        console.error("Failed to save message to Pinecone:", data.error, data.details);
        // If save fails, remove the message from savedMessageIds
        setSavedMessageIds(prev => {
          const newSet = new Set(prev);
          newSet.delete(messageId);
          return newSet;
        });
      }
    } catch (error) {
      console.error("Error saving message to Pinecone:", error);
      toast.error(`Error saving message: ${error.message}`);
      // If save fails, remove the message from savedMessageIds
      setSavedMessageIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(messageId);
        return newSet;
      });
    }
  }

  // handle resize for mobile and tablet
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const newIsMobile = width < 768;
      const newIsTablet = width >= 768 && width < 1024;
      setIsMobile(newIsMobile);
      setIsTablet(newIsTablet);
      setIsLeftSidebarOpen(!newIsMobile);
      setIsRightSidebarOpen(!newIsMobile && !newIsTablet); // comment out if it's to be always closed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth < 1024) {
        if (
          isLeftSidebarOpen &&
          leftSidebarRef.current &&
          !leftSidebarRef.current.contains(event.target) &&
          !event.target.closest(".left-menu-button")
        ) {
          setIsLeftSidebarOpen(false);
        }
        if (
          isRightSidebarOpen &&
          rightSidebarRef.current &&
          !rightSidebarRef.current.contains(event.target) &&
          !event.target.closest(".right-menu-button")
        ) {
          setIsRightSidebarOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, isLeftSidebarOpen, isRightSidebarOpen]);

  // calculate main content width based on sidebar states
  const calculateMainContentWidth = () => {
    if (isMobile) return "100%";

    let width = "100%";
    if (isLeftSidebarOpen) {
      width = "calc(100% - 16rem)"; // 16rem for left sidebar
    }
    if (isRightSidebarOpen && !isMobile) {
      width = `calc(${width} - 24rem)`; // 24rem for right sidebar
    }
    return width;
  };

  // handle streaming
  useEffect(() => {
    if (socket) {
      socket.on("start", (sessionId) => {
        console.log(`Started streaming for session ${sessionId}`);
        setIsStreaming(true);
      });

      socket.on("end", (sessionId) => {
        console.log(`Finished streaming for session ${sessionId}`);
        setIsStreaming(false);
        setMessageCounter(chatLog.length);
        setConversationId(sessionId);
      });

      socket.on("title", (isGeneratingTitle) => {
        if (!isGeneratingTitle) {
          setReloadConversations(true);
          socket.disconnect();
        }
      });

      socket.on("token", (token) => {
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              { ...lastMessage, content: lastMessage.content + token },
            ];
          } else {
            return [...newLog, { role: "ai", content: token }];
          }
        });
      });

      socket.on("sourceDocuments", (sourceDocuments) => {
        console.log("sourceDocuments", sourceDocuments);
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              {
                ...lastMessage,
                documentNames: sourceDocuments.map(
                  (document) => document.document
                ),
              },
            ];
          }
          return newLog;
        });
      });

      socket.on("usedTools", (usedTools) => {
        console.log("Used tools:", usedTools);
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              { ...lastMessage, toolNames: usedTools.map((tool) => tool.tool) },
            ];
          }
          return newLog;
        });
      });

      socket.on("nextAgent", (nextAgent) => {
        console.log("Next agent:", nextAgent);
      });

      socket.on("agentReasoning", (agentReasoning) => {
        console.log("Agent reasoning:", agentReasoning);
      });

      socket.on("abort", (sessionId) => {
        console.log(`Aborted streaming for session ${sessionId}`);
        setIsStreaming(false);
      });

      socket.on("error", (error) => {
        console.error("Error:", error);
      });
    }
  }, [socket]);

  useEffect(() => scrollToBottom(), [chatLog]);

  useEffect(() => {
    console.log(transcription);
    setInputPrompt(transcription);
  }, [transcription]);

  useEffect(() => {
    console.log("conversationID = ", conversationId);

    if (conversationId) {
      fetchMessagesForConversation(conversationId)
        .then((messages) => {
          const formattedMessages = messages.map((message) => ({
            role: message.role,
            content: message.content,
            toolNames: message.metadata?.toolNames || [],
          }));
          setChatLog(formattedMessages);
          scrollToBottom();
        })
        .catch(console.error);
    } else {
      setChatLog([]);
    }
  }, [conversationId]);

  // Adjust height whenever inputPrompt changes
  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [inputPrompt]);

  const fetchMessagesForConversation = async (conversationId) => {
    try {
      const response = await fetch(
        `${backendUrl}/zep/messages/${conversationId}`
      );
      if (!response.ok) throw new Error("Failed to fetch messages");
      const data = await response.json();
      return data.messages;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  };

  const scrollToBottom = () => {
    chatLogEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e, promptOverride = null) => {
    if (e) {
      e.preventDefault();
    }

    const currentPrompt = promptOverride || inputPrompt;

    if (!isStreaming && currentPrompt.trim() !== "") {
      setMessageCounter((prev) => prev + 1);
      setInputPrompt("");

      const userMessage = {
        role: "human",
        content: currentPrompt.replace(/\n/g, "  \n"),
      };
      setChatLog((prevLog) => [...prevLog, userMessage]);

      const id = user.id;

      try {
        const socket = socketIOClient(backendUrl);
        setSocket(socket);

        socket.emit("query", {
          message: currentPrompt,
          incomingSessionID: conversationId,
          userId: id,
        });

        socket.on("end", () => {
          setShowNextSteps(true);
        });
      } catch (error) {
        setErr(true);
        console.error(error);
      }
    } else {
      console.error("Invalid input prompt:", currentPrompt);
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    setInputPrompt(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const adjustTextareaHeight = (textarea) => {
    const lineHeight = 24; // Approximate line height in pixels (1.5em * 16px = 24px)
    const maxHeight = lineHeight * 10; // Maximum height for 10 lines

    textarea.style.height = "auto"; // Reset height
    const newHeight = Math.min(textarea.scrollHeight, maxHeight); // Cap at max-height
    textarea.style.height = `${newHeight}px`;

    // Enable scrolling if the content exceeds the maximum height
    textarea.style.overflowY =
      textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  // Pressing 'Enter' only creates a line break
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      return;
    }
  };

  //handle prompt sugestions
  // const handlePromptSelect = (prompt) => {
  //   const promptText = typeof prompt === "string" ? prompt : prompt.description;
  //   setInputPrompt(promptText);
  //   handleSubmit(null, promptText); // Pass the prompt directly to handleSubmit
  // };

  // handle next step prompt select
  const handleNextStepPromptSelect = (prompt) => {
    const promptText = typeof prompt === "string" ? prompt : prompt.description;
    setInputPrompt(promptText);
    handleSubmit(null, promptText);
    setShowNextSteps(false);
  };

  // handle prompt select from right nav
  const handleSelectPrompt = (prompt, exampleQuestion) => {
    const promptText =
      exampleQuestion ||
      (typeof prompt === "string" ? prompt : prompt.description);
    setInputPrompt(promptText);
    //handleSubmit(null, promptText); // automatically submit the prompt
  };

  // set the input prompt from the template catalog
  useEffect(() => {
    if (currentPrompt) {
      setInputPrompt(currentPrompt);
      //handleSubmit(null, currentPrompt); // automatically submit the prompt
      setCurrentPrompt("");
    }
  }, [currentPrompt]);

  // handle new conversation actions button
  const handleNewConversation = () => {
    setConversationId(null);
    setChatLog([]);
    setShowNextSteps(false);
    setReloadConversations(true);
  };

  useEffect(() => {
    if (currentPrompt === "delivery") {
      setSelectedCategory("Deliver");
    } else if (currentPrompt === "discovery") {
      setSelectedCategory("Discover");
    } else if (currentPrompt === "define") {
      setSelectedCategory("Define");
    } else if (currentPrompt === "develop") {
      setSelectedCategory("Develop");
    } else if (currentPrompt === "align") {
      setSelectedCategory("Align");
    }
  }, [currentPrompt]);

  const handlePromptSelect = (promptText, category) => {
    setInputPrompt(promptText);
    handleSubmit(null, promptText);
    setShowHero(false);

    // update the selected category in the prompt library
    if (category) {
      setSelectedCategory(category);
    }
  };

  if (!user) return <Loading />;

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden w-full">
      {/* LEFT SIDE BAR MENU BUTTON */}
      {/* <LeftMenuButton
        isOpen={isLeftSidebarOpen}
        toggleSidebar={toggleLeftSidebar}
      /> */}
      {/* LEFT SIDE BAR */}
      <aside
        ref={leftSidebarRef}
        className={`w-64 shadow-md transition-all duration-300 ease-in-out z-30 ${
          isLeftSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isMobile ? "fixed inset-y-0 left-0 pt-2" : "relative"}`}
        style={{
          width: isLeftSidebarOpen ? (isMobile ? "100%" : "16rem") : "0",
          minWidth: isLeftSidebarOpen ? (isMobile ? "100%" : "16rem") : "0",
          display: isLeftSidebarOpen ? "block" : "none",
        }}
      >
        {/* LEFT SIDEBAR CONTENT */}
        {/* <div className="h-full overflow-y-auto "> */}
        <Sidebar show={isLeftSidebarOpen} className="flex flex-col sm:flex-row">
          <NavContent
            setShowMenu={setIsLeftSidebarOpen}
            setConversationId={setConversationId}
            reloadConversations={reloadConversations}
            setReloadConversations={setReloadConversations}
            setChatLog={setChatLog}
            closeSidebar={() => setIsLeftSidebarOpen(false)}
            isMobile={isMobile}
            isTablet={isTablet}
            isOpen={isLeftSidebarOpen}
            setSelectedCategory={setSelectedCategory}
          />
        </Sidebar>
        {/* </div> */}
      </aside>
      {/* Main Content */}
      {/* <main className="flex-1 flex flex-col h-full overflow-hidden relative"> */}{" "}
      {/*  DO NOT ERASE */}
      <main
        style={{
          width: calculateMainContentWidth(),
          transition: "width 0.3s ease-in-out",
        }}
        className="flex-1 flex flex-col h-full overflow-hidden relative"
      >
        {/* Top Bar with Menu Buttons and NewConversationActions */}
        <div className="sticky top-0 z-40 bg-white bg-opacity-90 backdrop-blur-sm">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 md:px-6 py-2">
            <div className="flex items-start justify-between">
              {/* Left Menu Button */}
              <div className="flex-shrink-0">
                <LeftMenuButton
                  isOpen={isLeftSidebarOpen}
                  toggleSidebar={toggleLeftSidebar}
                />
              </div>

              {/* NewConversationActions */}
              <div className="flex-grow max-w-[calc(100%-120px)] sm:max-w-[calc(100%-160px)] mx-2 pt-2">
                {hasAIMessage && (
                  <NewConversationActions
                    conversationId={conversationId}
                    onNewConversation={handleNewConversation}
                    setReloadConversations={setReloadConversations}
                    setConversationId={setConversationId}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    pinnedConversations={pinnedConversations}
                    unpinnedConversations={unpinnedConversations}
                    reloadConversations={reloadConversations}
                  />
                )}
              </div>

              {/* Right Menu Button */}
              <div className="flex-shrink-0">
                {betaFeatures.promptLibrary && (
                  <RightMenuButton
                    isOpen={isRightSidebarOpen}
                    toggleSidebar={() =>
                      setIsRightSidebarOpen(!isRightSidebarOpen)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow overflow-y-auto flex flex-col">


          {/* PromptSuggestions */}
          {/* <div className="flex-1 px-4 sm:px-6 py-2 sm:py-4 flex flex-col">
            {showPrompts && chatLog.length === 0 && (
              <div className="mb-2 flex-shrink-0" id="prompt-suggestions">
                <PromptSuggestions
                  onSelect={handlePromptSelect}
                  currentPrompt={currentPrompt}
                />
              </div>
            )}
          </div> */}


          {/* HERO */}
          <div className="flex-1 px-4 sm:px-6 py-2 sm:py-4 flex flex-col">
            {showHero && chatLog.length === 0 && (
              <div className="flex-1 flex flex-col justify-center items-center">
                <Hero onPromptSelect={handlePromptSelect} />
              </div>
            )}
          </div>

          {/* CHAT */}
          {/* RENDER CONVOS */}

          <div className="flex-grow overflow-y-auto flex flex-col pb-4 w-full px-4 sm:px-6">
            {chatLog.map((message, idx) => {
              const isLastMessage = idx === chatLog.length - 1;
              const messageId = `message-${idx}`;
              const isMessageComplete = !isStreaming || idx < messageCounter;
              const isMessageSaved = savedMessageIds.has(messageId);

              return (
                <div
                  className={`flex flex-col mb-2 w-full relative ${
                    message.role === "human" ? "items-end" : "items-start"
                  }`}
                  key={idx}
                >
                  <div
                    className={`chat-message ${
                      message.role === "human" ? "user" : "assistant"
                    } relative`}
                  >
                    <MarkdownRenderer content={message.content} />

                    {/* Copy, save button and tool names (only for AI messages) */}
                    {message.role === "ai" && isMessageComplete && (
                      <div className="absolute bottom-2 right-2 flex">

                        {/* Save button */}
                        <button
                          className={`bg-transparent border-none cursor-pointer transition-colors duration-300 mr-2 ${
                            isMessageSaved ? "text-login-btn" : "text-primary hover:text-login-btn"
                          }`}
                          onClick={() => !isMessageSaved && handleSaveMessage(message.content, messageId)}
                          aria-label={isMessageSaved ? "Message saved" : "Save message"}
                          disabled={isMessageSaved}
            >
                          {isMessageSaved ? <FiCheck /> : <FiSave />}
                        </button>


                        {/* Copy button */}
                        <button
                          className={`bg-transparent border-none cursor-pointer text-primary hover:text-login-btn transition-colors duration-300 ${
                            copiedMessageId === messageId
                              ? "text-login-btn"
                              : ""
                          }`}
                          onClick={() =>
                            copyToClipboard(message.content, messageId)
                          }
                          aria-label={
                            copiedMessageId === messageId
                              ? "Copied to clipboard"
                              : "Copy to clipboard"
                          }
                        >
                          {copiedMessageId === messageId ? (
                            <FiCheck />
                          ) : (
                            <FiCopy />
                          )}
                        </button>


                      </div>
                    )}
                    {message.role === "ai" &&
                      message.toolNames &&
                      message.toolNames.length > 0 && (
                        <div className="flex flex-wrap mt-1">
                          {message.toolNames.map((tool, toolIdx) => (
                            <span
                              key={toolIdx}
                              className="bg-white text-primary text-xs px-2 py-1 my-1 rounded-md mr-1 mb-1 shadow-sm"
                            >
                              {tool}
                            </span>
                          ))}
                        </div>
                      )}
                  </div>
                  {/* Typing indicator */}
                  {isLastMessage &&
                    message.role === "ai" &&
                    (isStreaming || messageCounter > chatLog.length) && (
                      <div className="flex items-end mt-2 ml-2">
                        <span className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"></span>
                        <span
                          className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"
                          style={{ animationDelay: "0.2s" }}
                        ></span>
                        <span
                          className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                          style={{ animationDelay: "0.4s" }}
                        ></span>
                      </div>
                    )}
                </div>
              );
            })}
            <div ref={chatLogEndRef} className="pb-4" />
          </div>
        </div>

        <div className="flex-shrink-0 bg-white">
          {/* NextStepsPrompts */}
          {showNextSteps && chatLog.length > 0 && (
            <div className="px-3 sm:px-4 py-1 sm:py-2" id="next-steps-prompts">
              {betaFeatures.nextBestPrompt && (
                <NextStepsPrompts
                  chatLog={chatLog}
                  onSelect={handleNextStepPromptSelect}
                  isUserTyping={inputPrompt.length > 0}
                />
              )}
            </div>
          )}

          {/* PROMPT BAR */}
          <div id="prompt-input-bar" className="px-2 sm:px-4 py-1 sm:py-2">
            <PromptBar
              inputPrompt={inputPrompt}
              handleTextareaInput={handleTextareaInput}
              handleSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              handleKeyDown={handleKeyDown}
              isStreaming={isStreaming}
              setTranscription={setTranscription}
            />
          </div>
        </div>
      </main>
      {/* RIGHT SIDE NAV */}
      {betaFeatures.promptLibrary && (
        <aside
          ref={rightSidebarRef}
          className={`w-96 h-full overflow-y-auto transition-all duration-300 ease-in-out ${
            isRightSidebarOpen ? "translate-x-0" : "translate-x-full"
          } ${isMobile ? "fixed inset-y-0 right-0 pt-2" : "relative"}`}
          style={{
            width: isRightSidebarOpen ? (isMobile ? "100%" : "24rem") : "0",
            minWidth: isRightSidebarOpen ? (isMobile ? "100%" : "24rem") : "0",
          }}
        >
          <RightNav
            onSelectPrompt={handleSelectPrompt}
            isOpen={isRightSidebarOpen}
            setIsOpen={setIsRightSidebarOpen}
            toggleSidebar={() => setIsRightSidebarOpen(!isRightSidebarOpen)}
            closeSidebar={() => setIsRightSidebarOpen(false)}
            isMobile={isMobile}
            isTablet={isTablet}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </aside>
      )}
      {/* RIGHT SIDE BAR MENU BUTTON */}
      {/* {betaFeatures.promptLibrary && (
      <RightMenuButton
        isOpen={isRightSidebarOpen}
        toggleSidebar={() => setIsRightSidebarOpen(!isRightSidebarOpen)}
      />
      )} */}
      {/* ERROR MESSAGE */}
      {err && <Error setErr={setErr} />}
    </div>
  );
};

export default Home;
