import React from "react";
import { Accordion } from "flowbite-react";
import BackToHomeBtn from "../components/BackToHomeBtn";
import ContactForm from "../components/ContactForm";

const Faq = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <BackToHomeBtn />
      <h1 className="text-darkBrand font-semibold text-3xl my-10 text-center">
        Frequently Asked Questions
      </h1>
      <p className="text-gray-600 text-xl mb-14 text-center">
        Everything you need to know about Sindre AI Product Manager.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <Accordion>
            <Accordion.Panel>
              <Accordion.Title>
                What is Sindre AI Product Manager?
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">
                  Sindre AI Product Manager is an AI-driven solution designed to
                  enhance product management, growth, and sales processes. It
                  leverages generative AI to automate routine tasks, provide
                  data-driven insights, and work alongside human coworkers to
                  drive innovation and productivity.
                </p>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                How does Sindre integrate with existing workflows?
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">
                  Sindre integrates seamlessly with your existing tools and
                  workflows, providing real-time insights and automation
                  capabilities without disrupting your current processes. It is
                  designed to complement and enhance the productivity of your
                  team.
                </p>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                What are the main benefits of using Sindre?
              </Accordion.Title>
              <Accordion.Content>
                <ul className="list-disc pl-5 mb-2 text-gray-500">
                  <li>
                    <strong>Increased Productivity:</strong> Automate routine
                    tasks and focus on strategic decision-making.
                  </li>
                  <li>
                    <strong>Data-Driven Insights:</strong> Make informed
                    decisions with real-time analytics and predictive models.
                  </li>
                  <li>
                    <strong>Enhanced Collaboration:</strong> Work alongside
                    Sindre to enhance your team's capabilities and drive
                    innovation.
                  </li>
                </ul>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                How does Sindre ensure data privacy and security?
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">
                  Sindre is built with robust security protocols to ensure that
                  your data is protected at all times. We adhere to industry
                  best practices and compliance standards to safeguard your
                  information.
                </p>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                Can Sindre be customized to fit our specific needs?
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">
                  Yes, Sindre is highly customizable to fit the unique needs of
                  your business. Our team will work with you to tailor the
                  solution to your specific requirements and goals.
                </p>
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                What kind of support is available for Sindre users?
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500">
                  We offer comprehensive support for Sindre users, including
                  onboarding assistance, training, and ongoing technical support
                  to ensure that you get the most out of the solution.
                </p>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>

        <div className="md:sticky md:top-8 md:self-start">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Faq;
