import { useEffect } from 'react';

export const useResizeObserverFix = () => {
  useEffect(() => {
    const resizeObserverError = error => {
      if (error.message.includes('ResizeObserver loop')) {
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
      }
    };

    window.addEventListener('error', resizeObserverError);

    return () => {
      window.removeEventListener('error', resizeObserverError);
    };
  }, []);
};