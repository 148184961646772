import React, { useEffect, useRef, useState } from "react";
import Error from "./Error";
import { useAuth } from "../context/AuthContext";
import MarkdownRenderer from "./MarkdownRenderer";
import socketIOClient from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';
import PromptBar from "./PromptBar";
import './Onboarding.css'

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Onboarding = ({chatLog, setChatLog, userPrompt}) => {
  
  const { user } = useAuth();
  const [inputPrompt, setInputPrompt] = useState("");
  const [err, setErr] = useState(false);
  const chatLogEndRef = useRef(null);
  const [transcription, setTranscription] = useState("");
  const textareaRef = useRef(null);
  const [socket, setSocket] = useState(null);   
  const [isStreaming, setIsStreaming] = useState(false);
  const [sessionId] = useState(uuidv4());
  const [messageCounter, setMessageCounter] = useState(0); 


  const initializeZepSession = async () => {
    if (user && userPrompt) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/init-zep-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.id,
            fullName: user.user_metadata.full_name,
            userPrompt: userPrompt,
            sessionId: sessionId,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to initialize Zep session');
        }

        const data = await response.json();
        console.log('Zep session initialized:', data.sessionId);
      } catch (error) {
        console.error('Error initializing Zep session:', error);
      }
    }
  }

  useEffect(() => {
    initializeZepSession();
  }, [userPrompt]);

  useEffect(() => {
    if (socket) {
      socket.on("start", (sessionId) => {
        console.log(`Started streaming for session ${sessionId}`);
        setIsStreaming(true);
      });

      socket.on("end", (sessionId) => {
        console.log(`Finished streaming for session ${sessionId}`);
        setIsStreaming(false);
        setMessageCounter(chatLog.length);
      });

      socket.on("token", (token) => {
        setChatLog((prevLog) => {
          const newLog = [...prevLog];
          const lastMessage = newLog[newLog.length - 1];
          if (lastMessage && lastMessage.role === "ai") {
            return [
              ...newLog.slice(0, -1),
              { ...lastMessage, content: lastMessage.content + token },
            ];
          } else {
            return [...newLog, { role: "ai", content: token }];
          }
        });
      });

      socket.on("error", (error) => {
        console.error("Error:", error);
      });
    }
  }, [socket]);

  useEffect(() => scrollToBottom(), [chatLog]);

  useEffect(() => {
    console.log(transcription);
    setInputPrompt(transcription);
  }, [transcription]);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [inputPrompt]);

  const scrollToBottom = () => {
    chatLogEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStreaming && inputPrompt.trim() !== "") {
      setMessageCounter((prev) => prev + 1);
      setInputPrompt("");

      const userMessage = { role: "human", content: inputPrompt };
      setChatLog((prevLog) => [...prevLog, userMessage]);

      try {
        const socket = socketIOClient(backendUrl);
        setSocket(socket);

        socket.emit('onboarding', {
          message: inputPrompt,
          sessionId: sessionId
        });

      } catch (error) {
        setErr(true);
        console.error(error);
      }
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    setInputPrompt(textarea.value);
    adjustTextareaHeight(textarea);
  };


  const adjustTextareaHeight = (textarea) => {
    const lineHeight = 24; // Approximate line height in pixels (1.5em * 16px = 24px)
    const maxHeight = lineHeight * 10; // Maximum height for 10 lines
  
    textarea.style.height = "auto"; // Reset height
    const newHeight = Math.min(textarea.scrollHeight, maxHeight); // Cap at max-height
    textarea.style.height = `${newHeight}px`;
  
    // Enable scrolling if the content exceeds the maximum height
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };


  // Pressing 'Enter' only creates a line break
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      return;
    }
  };


  return (
    <div className="flex flex-col w-full h-full max-w-3xl mx-auto bg-white">

      {/* CHAT */}

      <div className="flex-grow overflow-y-auto p-5 flex flex-col  min-h-[50vh] sm:min-h-[30vh] md:min-h-[40vh] lg:min-h-[50vh]">
        {chatLog.map((message, idx) => {
          const isLastMessage = idx === chatLog.length - 1;

          return (
            <div
              className={`flex flex-col mb-4 w-full relative ${
                message.role === "human" ? "items-end" : "items-start"
              }`}
              key={idx}
            >
              <div
                className={`chat-message max-w-[70%] flex flex-col relative p-3 rounded-lg ${
                  message.role === "human"
                    ? "bg-lightBrand text-darkBrand"
                    : "bg-gray-100 text-gray-900"
                }`}
              >
                <MarkdownRenderer content={message.content} />
              </div>
              {isLastMessage &&
                message.role === "ai" &&
                (isStreaming || messageCounter > chatLog.length) && (
                  <div className="flex items-end mt-2">
                    <span className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"></span>
                    <span
                      className="w-2 h-2 bg-gray-500 rounded-full mr-1 animate-bounce"
                      style={{ animationDelay: "0.2s" }}
                    ></span>
                    <span
                      className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                      style={{ animationDelay: "0.4s" }}
                    ></span>
                  </div>
                )}
            </div>
          );
        })}
        <div ref={chatLogEndRef} />
      </div>

      {/* PROMPT BAR */}
      
     
        <PromptBar
          inputPrompt={inputPrompt}
          handleTextareaInput={handleTextareaInput}
          handleSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          handleKeyDown={handleKeyDown}
          setTranscription={setTranscription}
          isStreaming={isStreaming}
        />


      {/* ERROR MESSAGE */}
      {err && <Error setErr={setErr} />}
    </div>
  );
};

export default Onboarding;