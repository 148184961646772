import "./normal.css";
//import "./App.css";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Faq from "./pages/Faq";
import Prompts from "./pages/Prompts";
import OAuthCallback from "./pages/OauthCallback";
import ProfilePage from "./pages/ProfilePage";
import WelcomePage from "./pages/WelcomePage";
import NotFoundPage from "./pages/NotFoundPage";
import Loading from "./components/Loading";
import ResetPassword from "./components/ResetPassword";

const FileUpload = lazy(() => import("./components/FileUpload"));
const TemplateCatalog = lazy(() => import("./components/TemplateCatalog"));
const Integrations = lazy(() => import("./pages/Integrations"));

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="upload"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <FileUpload />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="prompts"
          element={
            <RequireAuth>
              <Prompts />
            </RequireAuth>
          }
        />
        <Route
          path="integrations"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <Integrations />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="templates"
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <TemplateCatalog />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route path="welcome" element={<WelcomePage />} />
        <Route path="faq" element={<Faq />} />
        <Route path="auth" element={<Auth />} />
        <Route path="oauthcallback" element={<OAuthCallback />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
