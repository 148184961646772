import React, { useState, useRef, useEffect } from "react";
import { getSupabase } from "../utils/supabase";
import { toast } from "react-hot-toast";
import { Avatar } from "flowbite-react";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { Camera, Loader } from "lucide-react";
import { motion } from "framer-motion";

const supabase = getSupabase();

function AvatarUpload({ user, onAvatarChange }) {

  const [avatarUrl, setAvatarUrl] = useState(user?.avatar_url);
  const [isUploading, setIsUploading] = useState(false);
  const [fullName, setFullName] = useState("");

  const fileInputRef = useRef(null);

  useEffect(() => {
    setAvatarUrl(user?.avatar_url);
    
    // Fetch the user's full name
    const fetchFullName = async () => {
      if (user?.id) {
        const { data, error } = await supabase
          .from("users")
          .select("full_name")
          .eq("id", user.id)
          .single();

        if (error) {
          console.error("Error fetching user's full name:", error);
        } else {
          setFullName(data.full_name || '');
        }
      }
    };

    fetchFullName();
  }, [user?.avatar_url, user?.id]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleUpload(file);
    }
  };

  const handleUpload = async (file) => {
    setIsUploading(true);
    try {
      const fileName = `${user.id}/avatar-${Date.now()}-${file.name}`;

      const { data, error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(fileName, file, {
          cacheControl: "3600",
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data: publicUrlData, error: urlError } = supabase.storage
        .from("avatars")
        .getPublicUrl(fileName);

      if (urlError) throw urlError;

      if (!publicUrlData || !publicUrlData.publicUrl) {
        throw new Error("Failed to get public URL");
      }

      const publicURL = publicUrlData.publicUrl;

      const { data: userData, error: updateError } = await supabase
        .from("users")
        .update({ avatar_url: publicURL })
        .eq("id", user.id)
        .select();

      if (updateError) throw updateError;

      setAvatarUrl(publicURL);
      onAvatarChange(publicURL);
      toast.success("Avatar uploaded successfully!");
    } catch (error) {
      console.error("Error in avatar upload process:", error);
      toast.error(`Failed to upload avatar: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };


  return (
    <div className="flex flex-col items-center">
      <Menu as="div" className="relative inline-block text-left">
        <MenuButton className="inline-flex justify-center items-center">
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Avatar 
              img={avatarUrl || `https://ui-avatars.com/api/?name=${fullName}&background=D9CCF4&color=5B3FA5`} 
              size="xl"
              rounded
              className="cursor-pointer"
            />
            <div className="absolute bottom-0 right-0 bg-white rounded-full p-1 shadow-md">
              {isUploading ? (
                <Loader className="h-5 w-5 text-gray-600 animate-spin" />
              ) : (
                <Camera className="h-5 w-5 text-gray-600" />
              )}
            </div>
          </motion.div>
        </MenuButton>
        <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            <MenuItem>
              {({ className }) => (
                <button
                  className={`${
                    className ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => fileInputRef.current.click()}
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Change Avatar"}
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        className="hidden"
      />
    </div>
  );
}

export default AvatarUpload;