import React, { useState, useEffect } from "react";
//import "./SignUpForm.css";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { sendToPipedream } from "../../utils/utils";
import { getSupabase } from "../../utils/supabase";
import ProgressModal from "../ProgressBar";

const supabase = getSupabase();



const SignupForm = () => {

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // progress modal
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);


  const { signUp, signInWithGoogle } = useAuth();

  const navigate = useNavigate();

  // create a new instance of the progress modal
  useEffect(() => {
    let interval;
    if (isProcessing) {
      interval = setInterval(() => {
        setProgress((prevProgress) => { // simulate progress
          if (prevProgress >= 95) {
            clearInterval(interval);
            return 95;
          }
          return prevProgress + 5;
        })
      }, 1000);
    } else if ( progress === 100) {
      setIsModalOpen(false);
    }
    return () => clearInterval(interval);
  },[isProcessing, progress]);



  const handleSignup = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsModalOpen(true); // progress modal
    setProgress(0);
    setIsProcessing(true)

    try {

        // Check if fullName contains at least two names separated by a space
        if (!fullName.trim().includes(" ") || fullName.trim().split(" ").length < 2) {
        setErrorMessage("Please enter your full name (first and last name).");
        setIsModalOpen(false); // progress modal

        return; // Stop the form submission
        }

      const user = await signUp(fullName, email, password); 
      console.log("User signed up:", user); // TODO: remove

      const domain = email.split('@')[1];
      console.log("Email domain:", domain); // TODO: remove

      // check if the email is not from standard domain
      const standardDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com', 'icloud.com'];
      if (!standardDomains.includes(domain)) {
        console.log("Non-standard domain detected. Sending to Pipedream..."); // TODO: remove
        const pipedreamData = {
          fullName,
          emailDomain: domain,
          userId: user.id
        };
        console.log("Data to be sent to Pipedream:", pipedreamData);
        try {
          const result = await sendToPipedream(pipedreamData);
          if (result.success && result.data && result.data.summary) {
            // Store the user_prompt in Supabase
            const { error: updateError } = await supabase
              .from('users')
              .update({ user_prompt: result.data.summary })
              .eq('id', user.id);

            if (updateError) throw updateError;
          } else {
            throw new Error("Invalid response from Pipedream");
          }
        } catch (pipedreamError) {
          console.error("Failed to process or store user data:", pipedreamError);
          setErrorMessage("Failed to process or store user data.");
        }
      }

      setProgress(100);
      setIsProcessing(false);

      navigate("/welcome");

    } catch (error) {
      console.error("Error during signup:", error);
      setErrorMessage(error.message || "An unexpected error occurred");
      setIsModalOpen(false);
      setIsProcessing(false);
    }
  };

    const handleSignUpWithGoogle = async () => {
      try {
        setIsModalOpen(true);
        setProgress(0);
        setIsProcessing(true);

        await signInWithGoogle();

        // setProgress(100);
        // setIsProcessing(false);

        // navigate("/welcome");

      } catch (error) {
        console.error("Error during Google signup:", error.message);
        setErrorMessage(error.message);
        setIsModalOpen(false);
        setIsProcessing(false);
      }
    };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-cyan-50 to-lightBrand">
    <div className="max-w-md w-full mx-auto p-6 bg-white rounded-lg shadow-md">
  
      <h1 className="text-2xl font-bold mb-6 text-center text-brandColor">Create your account</h1>
      <form onSubmit={handleSignup}>
        <div className="mb-4">
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
          />
        </div>
        <div className="relative mb-4">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="new-password"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {showPassword ? (
              <FaEyeSlash size={24} title="Hide Password" />
            ) : (
              <FaEye size={24} title="Show Password" />
            )}
          </button>
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-brandColor text-white rounded-md hover:bg-darkBrand focus:outline-none focus:ring-2 focus:ring-brandColor"
        >
          Continue
        </button>
        {/* {errorMessage && (
          <p className="mt-4 text-red-500 text-sm">{errorMessage}</p>
        )} */}
      </form>

      <div className="flex items-center my-6 w-full max-w-sm">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-4 text-gray-600 text-sm">OR</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      
      <button
        className="w-full flex items-center justify-center gap-4 py-3 border border-gray-300 rounded-md bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-brandColor"
        onClick={handleSignUpWithGoogle}
      >
        <FcGoogle size={24} />
        Continue with Google
      </button>
    </div>
    
      <ProgressModal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          setIsProcessing(false);
        }} 
        progress={progress}
      />

    </div>
  );
};

export default SignupForm;