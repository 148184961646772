import React, { useState, useMemo } from "react";
import { Card } from "flowbite-react";
import { motion } from "framer-motion";

const promptSuggestions = [
  "What can you do for me?",
  "How can you assist me today?",
  "What are your capabilities?",
  "Can you help me with my project?",
  "What services do you offer?",
  "How can you improve my workflow?",
  "What are the benefits of using this AI?",
  "Can you provide an overview of your features?",
  "How can you help me manage my tasks?",
  "What kind of support can you provide?",
  "Can you help me set up my project?",
  "What are the first steps to get started?",
  "How can you assist with my project planning?",
  "What tools do you integrate with?",
  "Can you help me track my progress?",
  "How can you improve my productivity?",
  "What are the best practices for using this AI?",
  "Can you provide tips for effective project management?",
  "How can you help me achieve my goals?",
  "What are the key features I should know about?",
  "What PDFs have I uploaded?",
  "Can you help me create OKRs for my product?",
  "Can you help me create a product brief?",
  "How do I manage project timelines?",
  "What are the best practices for product roadmaps?",
  "Can you help me with stakeholder communication?",
  "How do I prioritize features for my product?",
  "What are the key metrics for product success?",
  "How can I improve team collaboration?",
  "What tools can help with project management?",
  "How do I conduct a product market fit analysis?",
  "Can you assist with risk management in projects?",
  "What are the steps to create a project charter?",
  "Can you help me with stakeholder communication?",
  "How do I prioritize features for my product?",
  "What are the key metrics for product success?",
  "How can I improve team collaboration?"
];

const getRandomPrompts = (prompts, count, exclude) => {
  const filteredPrompts = prompts.filter(prompt => !exclude.includes(prompt));
  const shuffled = filteredPrompts.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

function PromptSuggestions ({ onSelect }) {

  const [previousPrompts, setPreviousPrompts] = useState([]);

  // Animation variants for the fade-in effect
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const cardVariants = {
    hover: { scale: 1.05 },
  };

  // Memoize the random prompts to avoid infinite re-renders
  const randomPrompts = useMemo(() => {
    return getRandomPrompts(promptSuggestions, 4, previousPrompts);
  }, [previousPrompts]);
 

  const combinedPrompts =  useMemo(() => [
    ...randomPrompts,
  ], [randomPrompts])

  
  return (
    <motion.div
      className="w-full max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 my-12 sm:my-8 mt-28"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4">
        {combinedPrompts.map((prompt, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            whileHover="hover"
          >
            <Card
              className="cursor-pointer text-gray-700 bg-lightBrand/30 hover:bg-lightBrand/50 transition shadow-sm hover:shadow-md h-full text-xs sm:text-sm"
              onClick={() => onSelect(prompt)}
            >

            <p className="text-xs sm:text-sm font-medium text-center leading-tight">{prompt}</p>
            </Card>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default PromptSuggestions;