import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSupabase } from '../utils/supabase';

const ResetPassword = () => {

  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const supabase = getSupabase();

  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) {
      setMessage('Invalid or expired reset link.');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data, error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      setMessage('Password updated successfully. Redirecting to login...');
      setTimeout(() => navigate('/auth'), 3000);
    } catch (error) {
      console.error('Error updating password:', error.message);
      setMessage(error.message);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-cyan-50 to-lightBrand">
      <div className="max-w-md w-full mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-brandColor text-center mb-6">Reset Password</h1>
        {message ? (
          <p className="text-center">{message}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="password"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                placeholder="New Password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brandColor"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-brandColor text-white rounded-md hover:bg-darkBrand focus:outline-none focus:ring-2 focus:ring-brandColor"
            >
              Update Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;