import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { HiInbox, HiTemplate, HiViewBoards, HiChartPie } from "react-icons/hi";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { RiToolsFill } from "react-icons/ri";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ToolsMenu = ({ onSelectTool }) => {
  const tools = [
    { 
      id: 1, 
      name: "Upload File", 
      icon: HiInbox, 
      href: "/upload",
      description: "Upload PDFs for tailored support and better understanding of your needs."
    },
    { 
      id: 2, 
      name: "Templates", 
      icon: HiTemplate, 
      href: "/templates",
      description: "Use Sindre's Templates to accelerate workflow and boost efficiency."
    },
    { 
      id: 3, 
      name: "Integrations", 
      icon: HiViewBoards, 
      href: "/integrations",
      description: "Integrate with Sindre to streamline workflow and enhance productivity."
    },
    { 
      id: 4, 
      name: "Prompt Planner", 
      icon: HiChartPie, 
      href: "/prompts",
      description: "Create and manage prompts for tailored insights and responses."
    },
  ];

  return (
    <div className="bg-white p-4 shadow-md rounded-lg mt-4">
      <Disclosure as="div" className="mb-2">
        {({ open }) => (
          <>
            <DisclosureButton className="text-xl font-semibold text-darkBrand flex items-center justify-center w-full">
              <RiToolsFill className="w-6 h-6 text-brandColor mr-2" />
              Tools
              <ChevronUpIcon
                className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-darkBrand transition-transform duration-300 ml-2`}
              />
            </DisclosureButton>
            <DisclosurePanel>
              <div className="mt-2 space-y-2">
                {tools.map((tool, index) => (
                  <motion.div
                    key={tool.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Disclosure as="div" className="border rounded-lg shadow-sm">
                      <DisclosureButton className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-gray-800 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 transition duration-300 ease-in-out">
                        <span className="flex items-center">
                          <tool.icon className="w-5 h-5 mr-2 text-brandColor" />
                          {tool.name}
                        </span>
                        <ChevronUpIcon className="w-5 h-5 text-purple-500 transition-transform duration-300" />
                      </DisclosureButton>
                      <DisclosurePanel className="px-4 pt-2 pb-2 text-gray-600">
                        <p className="text-left mb-2 text-sm leading-relaxed">
                          {tool.description}
                        </p>
                        <Link
                          to={tool.href}
                          onClick={() => onSelectTool(tool)}
                          className="flex items-center px-3 py-1 text-sm font-semibold text-white bg-brandColor rounded-full hover:bg-darkBrand transition duration-200"
                        >
                          Use tool
                        </Link>
                      </DisclosurePanel>
                    </Disclosure>
                  </motion.div>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default ToolsMenu;