import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Progress } from 'flowbite-react';
import { motion, AnimatePresence } from 'framer-motion';

// tips array for the progress bar
const tips = [
  "Use your AI Project Manager to break down large tasks into smaller, manageable steps.",
  "Ask your AI Project Manager to help prioritize tasks based on urgency and importance.",
  "Utilize your AI Project Manager to set and track project milestones.",
  "Your AI Project Manager can help identify potential risks and suggest mitigation strategies.",
  "Leverage your AI Project Manager to generate detailed project reports and analytics.",
  "Ask your AI Project Manager to create and manage project timelines and Gantt charts.",
  "Use your AI Project Manager to automate routine project management tasks and reminders.",
  "Consult your AI Project Manager for resource allocation suggestions and optimization.",
  "Request your AI Project Manager to analyze project data and provide insights for improvement.",
  "Use your AI Project Manager to facilitate communication between team members and stakeholders.",
  "Ask your AI Project Manager to generate meeting agendas and summarize key discussion points.",
  "Utilize your AI Project Manager to track and manage project budgets and expenses.",
  "Your AI Project Manager can help in creating and maintaining project documentation.",
  "Use your AI Project Manager to simulate different project scenarios and outcomes.",
  "Ask your AI Project Manager for personalized productivity tips based on your work patterns.",
  "Leverage your AI Project Manager to identify skill gaps in your team and suggest training opportunities.",
  "Use your AI Project Manager to monitor project health and provide early warnings for potential issues.",
  "Your AI Project Manager can assist in creating and managing quality assurance checklists.",
  "Ask your AI Project Manager to help with stakeholder management and communication strategies.",
  "Utilize your AI Project Manager for change management processes and impact assessments.",
];

// about Sindre
const about = [
  "Is designed to enhance product management, growth, and sales processes.",
  "Leverages generative AI to automate routine tasks provide data-driven insights",
  "Works alongside human coworkers to drive innovation and productivity.",
  "Integrates seamlessly with your existing tools and workflows, providing real-time insights.",
  "It is highly customizable to fit the unique needs of your business.",
  "Makes informed decisions with real-time analytics and predictive models.",
]

const ProgressModal = ({ isOpen, onClose, progress }) => {
  const [currentTip, setCurrentTip] = useState('');

  // Memoize the changeTip function to avoid recreating it on each render
  const changeTip = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * about.length);
    setCurrentTip(about[randomIndex]);
  }, []);

  useEffect(() => {
    if (isOpen) {
      changeTip(); // Set initial tip
      const interval = setInterval(changeTip, 5000); // Change tip every 5 seconds

      return () => clearInterval(interval);
    }
  }, [isOpen, changeTip]);

  // animation variants 
  const fadeInUpVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
  };

  const scaleXVariants = {
    initial: { scaleX: 0 },
    animate: { scaleX: 1 },
  };

  const fadeInOutVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <Modal show={isOpen} onClose={onClose} size="lg">
      <Modal.Body>
        <div className="space-y-8 px-8 pb-6 sm:pb-8 lg:px-10 xl:pb-10">
          <motion.h3
            variants={fadeInUpVariants}
            initial="initial"
            animate="animate"
            className="text-2xl font-medium text-brandColor"
          >
            Setting up your account...
          </motion.h3>
          <motion.div
            variants={scaleXVariants}
            initial="initial"
            animate="animate"
            transition={{ duration: 0.5 }}
          >
            <Progress progress={progress} size="lg" color="purple" />
          </motion.div>
          <AnimatePresence mode="wait">
            <motion.p
              key={currentTip}
              variants={fadeInOutVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
              className="text-lg text-gray-700 text-center"
            >
              <span className="font-bold">About Sindre:</span> {currentTip}
            </motion.p>
          </AnimatePresence>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressModal;
