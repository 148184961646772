import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const containerClass = "flex justify-center items-center";
const textClass = "logo-text text-brandColor text-center font-bold";

const sizeClasses = {
  small: "w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14",
  medium: "w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20",
  large: "w-48 h-48 sm:w-56 sm:h-56 md:w-64 md:h-64" 
};

const textSizeClasses = {
  small: "text-sm sm:text-base md:text-lg",
  medium: "text-base sm:text-lg md:text-xl",
  large: "text-4xl sm:text-5xl md:text-6xl"
};


function Logo({ size = 'small', clickable = true }) {
  const navigate = useNavigate();
  const handleReload = () => {
    window.location.reload();
    navigate("/")
  };

  return (
    <div className={containerClass}>
      <div 
        className={`flex flex-col items-center transition-transform duration-300 hover:scale-105 ${clickable ? "cursor-pointer" : ""}`} 
        onClick={clickable ? handleReload : undefined} 
      >
        <img 
          src="/sindre.png" 
          alt="Sindre logo" 
          className={sizeClasses[size]} 
        />
        <h1 className={`${textClass} ${textSizeClasses[size]} mt-1`}>
          Sindre AI
        </h1>
      </div>
    </div>
  );
}

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  clickable: PropTypes.bool
};

export default Logo;
