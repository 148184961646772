import React, { useState, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FileInput, Label, Progress, Button } from "flowbite-react";
import { toast } from 'react-hot-toast';
import axios from 'axios';


const WelcomeFileUpload = ({ onComplete, onPrevious }) => {

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { user } = useAuth();

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);


  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      uploadFiles(selectedFiles);
    }
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer?.files);
    if (droppedFiles.length > 0) {
      uploadFiles(droppedFiles);
    } else {
      toast.error('Error: No file selected');
    }
  }, []);

  const uploadFiles = async (files) => {
    setUploadingFiles(files);
    for (const file of files) {
      await uploadFile(file);
    }
    setUploadingFiles([]);
    onComplete();
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', user.id);

    try {
      await axios.post(`${backendUrl}/upload/pdf`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({...prev, [file.name]: progress}));
        },
      });

      toast.success(`Success: ${file.name} uploaded successfully!`);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setUploadProgress(prev => ({...prev, [file.name]: 0}));
    }
  };




  return (
    <div className="bg-white flex flex-col items-center justify-start w-full max-w-md mx-auto p-5 mb-4">
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        className="flex w-full items-center justify-center mb-4"
      >
        <Label
          htmlFor="dropzone-file"
          className={`flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed ${
            isDragging ? "border-brandColor bg-teal-50" : "border-gray-300 bg-gray-50 hover:bg-gray-100"
          } transition-colors duration-300 ease-in-out`}
        >
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <FaCloudUploadAlt className={`mb-2 h-6 w-6 ${isDragging ? "text-brandColor" : "text-gray-500"}`} />
            <p className={`mb-2 text-sm ${isDragging ? "text-brandColor" : "text-gray-500"}`}>
              <span className="font-semibold">{isDragging ? "Drop the file here" : "Click to upload"}</span>{" "}
              {!isDragging && "or drag and drop"}
            </p>
            <p className={`text-xs ${isDragging ? "text-brandColor" : "text-gray-500"}`}>PDF files only</p>
          </div>
          <FileInput
            id="dropzone-file"
            className="hidden"
            onChange={handleFileChange}
            accept=".pdf"
          />
        </Label>
      </div>

      {uploadingFiles.length > 0 && (
        <div className="w-full mt-4">
          {uploadingFiles.map((file, index) => (
            <div key={index} className="mb-2">
              <p className="text-sm text-gray-600 mb-1">Uploading: {file.name}</p>
              <Progress progress={uploadProgress[file.name] || 0} color="purple" size="md" />
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-between w-full mt-6">
        <Button color="light" onClick={onPrevious}>
          Previous
        </Button>
        <Button color="purple" onClick={onComplete}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default WelcomeFileUpload;