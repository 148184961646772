import React, { useRef, useState } from 'react';
import { Button, Label, TextInput, Textarea } from 'flowbite-react';
import emailjs from '@emailjs/browser';
import { toast } from "react-hot-toast";

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
      .then((result) => {
        console.log('EmailJS response:', result);
        toast.success('Message sent successfully!');
        setFormData({ user_name: '', user_email: '', message: '' });
      }, (error) => {
        console.error('EmailJS error:', error);
        toast.error('Failed to send message. Please try again.');
      });
  };

  return (
    <div>
      <h2 className="text-lg font-semibold text-darkBrand mb-3">Feedback to Sindre.ai Team</h2>
      <form ref={form} onSubmit={handleSubmit} className="flex flex-col gap-3">
        <div>
          <Label htmlFor="user_name" value="Your name" className='text-sm'/>
          <TextInput
            id="user_name"
            name="user_name"
            type="text"
            placeholder="Your name here..."
            required
            value={formData.user_name}
            onChange={handleChange}
            className='text-sm'
          />
        </div>
        <div>
          <Label htmlFor="user_email" value="Your email" className='text-sm'/>
          <TextInput
            id="user_email"
            name="user_email"
            type="email"
            placeholder="your@email.com here..."
            required
            value={formData.user_email}
            onChange={handleChange}
            className='text-sm'
          />
        </div>
        <div>
          <Label htmlFor="message" value="Your message" className='text-sm'/>
          <Textarea
            id="message"
            name="message"
            placeholder="Leave a comment..."
            required
            rows={4}
            value={formData.message}
            onChange={handleChange}
            className='text-sm'
          />
        </div>
        <Button type="submit" color="purple" size="sm">Send message</Button>
      </form>
    </div>
  );
};

export default ContactForm;