import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const containerClass = "flex items-center";
const textClass = "logo-text text-brandColor font-bold ml-2 sm:ml-3 transition-colors duration-300";

const sizeClasses = {
  small: "w-8 h-8",
  medium: "w-10 h-10",
  large: "w-12 h-12",
  responsive: "w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10"
};

const textSizeClasses = {
  small: "text-sm",
  medium: "text-base",
  large: "text-lg",
  responsive: "text-xs sm:text-sm md:text-base"
};

function NavbarLogo({ size = 'small', clickable = true }) {
  const navigate = useNavigate();
  
  const handleReload = () => {
    window.location.reload();
    navigate("/")
  };

  return (
    <motion.div 
      className={`${containerClass} ${clickable ? "cursor-pointer" : ""}`}
      onClick={clickable ? handleReload : undefined}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <motion.img 
        src="/sindre.png" 
        alt="Sindre logo" 
        className={`${sizeClasses[size]} transition-transform duration-300 hover:rotate-12`}
      />
      <h1 className={`${textClass} ${textSizeClasses[size]} hover:text-darkBrand hidden sm:block`}>
        Sindre AI
      </h1>
    </motion.div>
  );
}

NavbarLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'responsive']),
  clickable: PropTypes.bool
};

export default NavbarLogo;