import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import {
  Card,
  Button,
  ToggleSwitch,
  TextInput,
  Label,
  Avatar,
  Textarea,
  Modal,
} from "flowbite-react";
import {
  HiMail,
  HiLockClosed,
  HiPencil,
  HiOutlineLogout,
  HiRefresh,
} from "react-icons/hi";
import { getSupabase } from "../utils/supabase";
import { toast } from "react-hot-toast";
import { updateUserFullName } from "../utils/utils";
import { useBetaFeatures } from "../context/BetaFeaturesContext";
import BackToHomeBtn from "../components/BackToHomeBtn";
import AvatarUpload from "../components/AvatarUpload";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../components/Loading";
import Error from "../components/Error";
import ContactForm from "../components/ContactForm";
import { useNavigate } from "react-router-dom";

const supabase = getSupabase();

// animation variants
const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 },
};

const cardVariants = {
  initial: { opacity: 0, scale: 0.95 },
  in: { opacity: 1, scale: 1 },
  hover: { scale: 1.02, transition: { duration: 0.3 } },
};

function ProfilePage() {
  const { user, setUser, signOut } = useAuth();

  const { betaFeatures, toggleFeature } = useBetaFeatures();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userPrompt, setUserPrompt] = useState("");
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const [defaultUserPrompt, setDefaultUserPrompt] = useState("");

  const navigate = useNavigate();

  // fetch user data
  useEffect(() => {
    // check if user ir logged in. Before this if/else was not here
    if (!user) {
      navigate("/auth");
    } else {
      const fetchUserData = async () => {
        if (user?.id) {
          try {
            setIsLoading(true);
            setError(null);
            await updateUserFullName(supabase, user.id);
            const { data, error } = await supabase
              .from("users")
              .select("full_name, avatar_url, user_prompt")
              .eq("id", user.id)
              .single();
  
            if (error) throw error;
            setFullName(data.full_name || "N/A");
            setUserPrompt(data.user_prompt || "");
            setUser((prevUser) => ({
              ...prevUser,
              avatar_url: data.avatar_url,
              user_prompt: data.user_prompt,
            }));
          } catch (error) {
            console.error("Error fetching user data:", error);
            setError("Failed to fetch user data. Please try again later.");
          } finally {
            setIsLoading(false);
          }
        }
      };
      fetchUserData();
    }
  }, [user?.id, setUser]);

  // handle update user prompt
  const handleUpdateUserPrompt = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ user_prompt: userPrompt })
        .eq("id", user.id);

      if (error) throw error;
      toast.success("Information updated successfully!");
      setIsEditingPrompt(false);
    } catch (error) {
      console.error("Error updating user information:", error);
      toast.error("Failed to update information. Please try again.");
    }
  };

  // reset password
  const handlePasswordReset = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!");
      return;
    }
    try {
      setIsLoading(true);
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) throw error;
      toast.success("Password updated successfully!");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("Failed to update password.");
      console.error("Error updating password:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // handle avatar change
  const handleAvatarChange = (avatarUrl) => {
    setUser((prevUser) => ({ ...prevUser, avatar_url: avatarUrl }));
  };

  // fetch default_user_prompt
  const fetchDefaultUserPrompt = async () => {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("default_user_prompt")
        .eq("id", user.id)
        .single();

      if (error) throw error;
      setDefaultUserPrompt(data.default_user_prompt);
      return data.default_user_prompt;
    } catch (error) {
      console.error("Error fetching default user prompt:", error);
      return null;
    }
  };

  // handle reset default_user_prompt
  const handleResetToDefault = async () => {
    const defaultPrompt = await fetchDefaultUserPrompt();
    if (defaultPrompt) {
      try {
        const { error } = await supabase
          .from("users")
          .update({ user_prompt: defaultPrompt })
          .eq("id", user.id);

        if (error) throw error;
        setUserPrompt(defaultPrompt);
        toast.success("Information updated successfully!");
      } catch (error) {
        console.error("Error resetting to default prompt:", error);
        toast.error("Failed to reset to default prompt. Please try again.");
      }
    }
  };

  // handle logout
  const handleLogOut = async (event) => {
    event.preventDefault();
    await signOut();
    navigate("/auth");
  };

  if (isLoading) return <Loading />;

  if (error) return <Error err={error} />;

  if (!user) {
    return (
      <div className="container mx-auto p-4 max-w-3xl">
        <Card>
          <h2 className="text-2xl font-bold text-red-600 mb-2">Error</h2>
          <p>User not found. Please log in again.</p>
        </Card>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-3 max-w-4xl mt-6"
    >
      {/* Logout and Back to Home */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="flex justify-between items-center mb-4"
      >
        <BackToHomeBtn />

        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button color="gray" onClick={handleLogOut} size="sm">
            <HiOutlineLogout className="mr-2" />
            Log out
          </Button>
        </motion.div>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* User Profile */}
        <motion.div
          className="md:col-span-2"
          variants={cardVariants}
          initial="initial"
          animate="in"
          whileHover="hover"
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <Card className="p-4 flex flex-col h-full">
            <div className="flex-grow flex flex-col justify-between">
              <div className="flex flex-col items-center space-y-3">
                <AvatarUpload user={user} onAvatarChange={handleAvatarChange} />
                <div className="flex flex-col text-left space-y-1">
                  <h2 className="text-2xl font-bold text-darkBrand">
                    {fullName}
                  </h2>
                  <div className="flex items-center justify-center text-gray-600 text-sm">
                    <HiMail className="mr-1" aria-hidden="true" />
                    <span>{user?.email || "N/A"}</span>
                  </div>
                </div>
              </div>

              <div className="w-full mt-4 flex-grow">
                <h3 className="text-lg font-semibold text-darkBrand mb-2">
                  My Information
                </h3>
                <div className=" bg-gray-100 p-3 rounded-lg">
                  <p className="text-gray-700 whitespace-pre-wrap text-sm">
                    {userPrompt || "No information available."}
                  </p>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                  <Button
                    color="purple"
                    onClick={() => setIsEditingPrompt(true)}
                    size="xs"
                  >
                    <HiPencil className="mr-1" />
                    Edit
                  </Button>
                  <Button color="gray" onClick={handleResetToDefault} size="xs">
                    <HiRefresh className="mr-1" />
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </motion.div>

        {/* Beta Features, Reset Password */}
        <motion.div
          className="md:col-span-1 flex flex-col space-y-4"
          variants={cardVariants}
          initial="initial"
          animate="in"
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <motion.div whileHover="hover" variants={cardVariants}>
            <Card className="p-4 flex-grow">
              <h3 className="text-lg font-semibold text-darkBrand mb-3">
                Beta Features
              </h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span className="font-medium text-sm">Next Best Prompt</span>
                  <ToggleSwitch
                    checked={betaFeatures.nextBestPrompt}
                    onChange={() => toggleFeature("nextBestPrompt")}
                    className="scale-90"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <span className="font-medium text-sm">Prompt Library</span>
                  <ToggleSwitch
                    checked={betaFeatures.promptLibrary}
                    onChange={() => toggleFeature("promptLibrary")}
                    className="scale-90"
                  />
                </div>
              </div>
            </Card>
          </motion.div>

          <motion.div whileHover="hover" variants={cardVariants}>
            <Card className="p-4 flex-grow">
              <h3 className="text-lg font-semibold text-darkBrand mb-3">
                Reset Password
              </h3>
              <form onSubmit={handlePasswordReset} className="space-y-4">
                <div>
                  <Label htmlFor="new-password" className="mb-1 text-sm">
                    New Password
                  </Label>
                  <TextInput
                    id="new-password"
                    type="password"
                    icon={HiLockClosed}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="text-sm"
                  />
                </div>
                <div>
                  <Label htmlFor="confirm-password" className="mb-1 text-sm">
                    Confirm New Password
                  </Label>
                  <TextInput
                    id="confirm-password"
                    type="password"
                    icon={HiLockClosed}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="text-sm"
                  />
                </div>
                <Button type="submit" color="purple" size="sm">
                  Reset Password
                </Button>
              </form>
            </Card>
          </motion.div>
        </motion.div>
      </div>

      {/* Feedback Form */}

      <motion.div
        className="mt-6"
        variants={cardVariants}
        initial="initial"
        animate="in"
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        <Card className="p-4">
          <ContactForm />
        </Card>
      </motion.div>

      {/* Edit My Information Modal */}

      <AnimatePresence>
        {isEditingPrompt && (
          <Modal
            show={isEditingPrompt}
            onClose={() => setIsEditingPrompt(false)}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <Modal.Header>Edit My Information</Modal.Header>
              <Modal.Body>
                <Textarea
                  id="user-prompt"
                  value={userPrompt}
                  onChange={(e) => setUserPrompt(e.target.value)}
                  rows={10}
                  className="mb-3 text-sm"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  color="gray"
                  onClick={() => setIsEditingPrompt(false)}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button
                  color="purple"
                  onClick={handleUpdateUserPrompt}
                  size="sm"
                >
                  Save
                </Button>
              </Modal.Footer>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default ProfilePage;