import React, { useState, useEffect } from "react";
import {
  FaEdit,
  FaSave,
  FaClock,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { createClient } from "@supabase/supabase-js";
import MarkdownRenderer from "../components/MarkdownRenderer";
import { useAuth } from "../context/AuthContext";
import BackToHomeBtn from "../components/BackToHomeBtn";
import { Button, Label, Textarea, Select } from "flowbite-react";
import { promptLibrary } from "../utils/data";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const ResponseModal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-4xl max-h-[80vh] overflow-y-auto relative">
        <button className="absolute top-2 right-2 text-2xl" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="mt-6">
          <MarkdownRenderer content={content} />
        </div>
      </div>
    </div>
  );
};

const Prompts = () => {
  const { user } = useAuth();
  const [prompts, setPrompts] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedPrompt, setEditedPrompt] = useState("");
  const [newPrompt, setNewPrompt] = useState("");
  const [newFrequency, setNewFrequency] = useState("weekly");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    fetchPrompts();
  }, [user]);

  const fetchPrompts = async () => {
    const { data, error } = await supabase
      .from("prompts")
      .select("*")
      .eq("user_id", user.id);
    if (error) console.error("Error fetching prompts:", error);
    else setPrompts(data);
  };

  const handleEdit = (id, prompt) => {
    setEditingId(id);
    setEditedPrompt(prompt);
  };

  const handleSave = async (id) => {
    const { error } = await supabase
      .from("prompts")
      .update({ prompt: editedPrompt })
      .eq("id", id);
    if (error) console.error("Error updating prompt:", error);
    else {
      setEditingId(null);
      fetchPrompts();
    }
  };

  const handleFrequencyChange = async (id, frequency) => {
    const { error } = await supabase
      .from("prompts")
      .update({ frequency })
      .eq("id", id);
    if (error) console.error("Error updating frequency:", error);
    else fetchPrompts();
  };

  const handleCreatePrompt = async (e) => {
    e.preventDefault();
    const { error } = await supabase.from("prompts").insert({
      prompt: newPrompt,
      frequency: newFrequency,
      user_id: user.id,
    });
    if (error) console.error("Error creating prompt:", error);
    else {
      setNewPrompt("");
      setNewFrequency("weekly");
      fetchPrompts();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this prompt?")) {
      const { error } = await supabase.from("prompts").delete().eq("id", id);
      if (error) console.error("Error deleting prompt:", error);
      else fetchPrompts();
    }
  };

  const handleShowResponse = (response) => {
    setModalContent(response);
    setModalOpen(true);
  };

  const selectPrompt = (prompt) => {
    setNewPrompt(prompt.title);
  };

  return (
    <div className="bg-gray-100 min-h-screen w-full font-sans p-5 relative">
      <BackToHomeBtn />

      <h1 className="text-darkBrand font-bold text-4xl my-10 text-center">
      Schedule Prompts
      </h1>

      <h2 className="text-gray-600 text-xl mb-8 text-center max-w-3xl mx-auto">
        Create and manage your prompts to guide Sindre in delivering the
        insights and responses you need. Set the frequency to keep your content fresh and up-to-date.
      </h2>

      <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
        <div className="lg:w-2/3 space-y-8">
          <form
            onSubmit={handleCreatePrompt}
            className="bg-white p-6 rounded-lg shadow-md w-full"
          >
            <h3 className="text-darkBrand font-semibold text-2xl mb-6">Plan Your Prompt Timeline</h3>
            <div className="mb-4">
              <Label htmlFor="newPrompt" value="New Prompt" className="text-lg" />
              <Textarea
                id="newPrompt"
                value={newPrompt}
                onChange={(e) => setNewPrompt(e.target.value)}
                placeholder="Enter new prompt"
                required
                rows={4}
                className="mt-2"
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="newFrequency" value="Frequency" className="text-lg" />
              <Select
                id="newFrequency"
                value={newFrequency}
                onChange={(e) => setNewFrequency(e.target.value)}
                className="mt-2"
              >
                <option value="never">Never</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Select>
            </div>
            <Button type="submit" color="purple" size="lg" className="w-full mt-4">
            Schedule Prompt
            </Button>
          </form>

          <div className="space-y-6 w-full">
            <h3 className="text-darkBrand font-semibold text-2xl mb-6">Stored Prompts:</h3>
            {prompts.length === 0 ? ( // Check if there are no prompts
              <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm text-center">
                <p className="text-gray-600 text-lg">You don't have any stored prompts yet.</p>
                <p className="text-gray-500 mt-2">Schedule a new prompt to get started!</p>
              </div>
            ) : (
              prompts.map((prompt) => (
                <div
                  key={prompt.id}
                  className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm transition-shadow hover:shadow-md"
                >
                  {editingId === prompt.id ? (
                    <>
                      <Textarea
                        value={editedPrompt}
                        onChange={(e) => setEditedPrompt(e.target.value)}
                        className="mb-4"
                        rows={4}
                      />
                      <Button
                        onClick={() => handleSave(prompt.id)}
                        color="success"
                        className="my-4"
                      >
                        <FaSave className="mr-2" /> Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <p className="mb-4 text-lg">{prompt.prompt}</p>
                      <div className="flex flex-wrap gap-2">
                        <Button
                          onClick={() => handleEdit(prompt.id, prompt.prompt)}
                          color="warning"
                        >
                          <FaEdit className="mr-2" /> Edit
                        </Button>
                        <Button
                          onClick={() => handleShowResponse(prompt.last_response)}
                          color="purple"
                        >
                          Show Latest Response
                        </Button>
                        <Button
                          onClick={() => handleDelete(prompt.id)}
                          color="failure"
                        >
                          <FaTrash className="mr-2" /> Delete
                        </Button>
                      </div>
                    </>
                  )}
                <div className="flex items-center mt-4">
                  <FaClock className="mr-2 text-gray-600" />
                  <Select
                    value={prompt.frequency}
                    onChange={(e) =>
                      handleFrequencyChange(prompt.id, e.target.value)
                    }
                    className="w-full"
                  >
                    <option value="never">Never</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </Select>
                </div>
              </div>
              ))
            )}
          </div>


        </div>

        <div className="lg:w-1/3">
          <div className="bg-white p-6 rounded-lg shadow-md sticky top-5">
            <h3 className="text-darkBrand font-semibold text-2xl mb-6">Prompt Suggestions</h3>
            <p className="text-gray-600 text-sm mb-4">
              Click on any suggestion below to use it as a starting point for your prompt. You can then edit it as needed.
            </p>
            <ul className="space-y-3">
              {promptLibrary.map((prompt) => (
                <li key={prompt.id}>
                  <button
                    onClick={() => selectPrompt(prompt)}
                    className="w-full text-left p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
                  >
                    <span className="font-semibold text-lg block mb-1">{prompt.title}</span>
                    <span className="text-sm text-gray-600">{prompt.description}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <ResponseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        content={modalContent}
      />
    </div>
  );
};

export default Prompts;