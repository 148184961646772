import React from "react";
import { motion } from "framer-motion";
import { Plus } from "lucide-react";

const NewConversationButton = ({ onClick, className = "", disabled = false }) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled) {
      onClick?.();
    }
  };

  const buttonVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  const iconVariants = {
    hover: { rotate: 90 },
    tap: { scale: 0.9, rotate: 45 },
  };


  return (
    <motion.button
      className={`
        flex items-center justify-center rounded-lg
        bg-brandColor
        transition-all duration-300 ease-in-out
        hover:bg-darkBrand hover:shadow-lg
        focus:outline-none
        ${disabled ? "opacity-50 cursor-not-allowed" : ""}
        ${className}
      `}
      onClick={handleClick}
      variants={buttonVariants}
      whileHover="hover"
      whileTap="tap"
      disabled={disabled}
      aria-label="Start a new conversation"
    >
      <motion.div variants={iconVariants}>
        <Plus className="w-4 h-4 md:w-6 md:h-6"  color="white" strokeWidth={2} />
      </motion.div>
    </motion.button>
  );
};

export default NewConversationButton;