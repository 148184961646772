import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Modal } from "flowbite-react";
import WelcomeFileUpload from "../components/WelcomeFileUpload";
import WelcomeIntegrations from "../components/WelcomeIntegrations";
import OnboardingChat from "../components/OnboardingChat";
import OnboardingProgress from "../components/OnboardingProgress";
//import ReminderPdfBubble from "../components/ReminderPdfBubble";
//import ReminderIntegrationsBubble from "../components/RemiderIntegrationsBubble";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ConfettiEffect from "../components/ConfettiEffect";



function WelcomePage() {


  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [step, setStep] = useState(1);
  //const [showReminder, setShowReminder] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  // const fileInputRef = useRef(null);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const reminderTimeout = setTimeout(() => {
  //     setShowReminder(true);
  //   }, 5000);

  //   return () => clearTimeout(reminderTimeout);
  // }, []);

  // const triggerFileUpload = () => {
  //   fileInputRef.current.click();
  // };

  const steps = [
    {
      title: "Chat with Sindre",
      component: (
        <OnboardingChat
          userData={userData}
          setUserData={setUserData}
          onComplete={() => handleNextStep()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
    {
      title: "Upload PDF Files",
      component: (
        <WelcomeFileUpload
          onComplete={() => handleNextStep()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
    {
      title: "Setup Integrations",
      component: (
        <WelcomeIntegrations
          onComplete={() => handleCompleteOnBoarding()}
          onPrevious={() => handlePreviousStep()}
        />
      ),
    },
  ];

  const handleNextStep = () => {
    if (step < steps.length) {
      setStep(step + 1);
    } else {
      handleCompleteOnBoarding();
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleStepClick = (index) => {
    setStep(index + 1);
  };

  const handleCompleteOnBoarding = () => {
    setShowConfetti(true);
    setShowModal(true);
    setTimeout(() => {
      setShowConfetti(false);
      setShowModal(false);
      navigate("/");
    }, 3000);
  };

  // const handleMoveToHome = () => {
  //   setShowModal(false);
  //   setShowConfetti(false);
  //   navigate("/");
  // }

  // Framer Motion
  const pageVariants = {
    initial: { opacity: 0, y: 50 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  const buttonVariants = {
    idle: { scale: 1 },
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-cyan-50 to-lightBrand flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Card className="w-full max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
            <h1 className="text-center text-5xl font-extrabold text-brandColor sm:text-6xl">
              Welcome to Sindre
            </h1>
            <p className="mt-2 text-center text-lg text-gray-600">
              Let's get you set up for success!
            </p>
            <div className="mt-8">
              <OnboardingProgress
                steps={steps}
                currentStep={step}
                onStepClick={handleStepClick}
              />
              <div className="mt-4">
                <h2 className="text-2xl font-bold text-darkBrand">
                  {steps[step - 1].title}
                </h2>
                {steps[step - 1].component}
              </div>
            </div>
          </Card>
        </motion.div>
      </AnimatePresence>

      {/* Confetti  */}
      <ConfettiEffect show={showConfetti} />

      {/* END OF ONBOARDING MODAL */}
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        size="lg"
        popup
      >
        <Modal.Header className="hidden" />
        <Modal.Body className="p-8">
          <div className="flex justify-center w-full mb-6">
            <h2 className="text-2xl font-bold text-center text-darkBrand">
              Thank you for onboarding with Sindre!
            </h2>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/66a0daf034f5b2211c4a3e03/66abab9d9f28c7211240a58b_Sindre%20circle%201280x1280.png"
            alt="Sindre logo"
            className="w-32 h-32 mx-auto mb-4"
          />
          {/* <div className="flex justify-center gap-6">
            <Button onClick={handleMoveToHome} color={"purple"}>
              Let's get started!
            </Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WelcomePage;
