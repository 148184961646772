import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Ensure correct import
import { isUserInDatabase, addUser, handleSlackIntegration } from '../utils/utils';
import { getSupabase } from '../utils/supabase';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const supabase = getSupabase();

  useEffect(() => {
    // Check for access_token in the fragment (Slack)
    const hash = window.location.hash.substring(1);
    const fragmentParams = new URLSearchParams(hash);
    const accessToken = fragmentParams.get('access_token');

    if (accessToken) {
      // Handle Slack Integration
      const decodedToken = jwtDecode(accessToken);

      const user = {
        id: decodedToken.sub,
        email: decodedToken.email,
      };

      async function handleUser() {
        const userInDatabase = await isUserInDatabase(user);

        if (!userInDatabase) {
          await addUser(user);
        }

        const slackUserId = localStorage.getItem('slack_user_id');
        if (slackUserId) await handleSlackIntegration(user.id, slackUserId);

        navigate('/');
      }

      handleUser();
    } else {
      // Check for code in the query parameters (Jira)
      const search = window.location.search.substring(1);
      const queryParams = new URLSearchParams(search);
      const code = queryParams.get('code');

      if (code) {
        // Handle Jira Integration
        async function handleJiraOAuthCallback() {
          try {
            // Send the code to your backend to exchange for tokens
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/integrations/jira/exchange-code`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // Include user's access token if needed for authentication
                  Authorization: `Bearer ${supabase.auth.session()?.access_token}`,
                },
                body: JSON.stringify({ code }),
                credentials: 'include',
              }
            );

            if (!response.ok) {
              throw new Error('Failed to exchange authorization code for tokens');
            }

            const data = await response.json();

            // Store tokens in Supabase
            await supabase.from('integrations').upsert(
              {
                user_id: supabase.auth.user().id,
                name: 'Jira',
                data: {
                  access_token: data.access_token,
                  refresh_token: data.refresh_token,
                  expires_at: Date.now() + data.expires_in * 1000,
                  jira_base_url: data.jira_base_url,
                  cloud_id: data.cloud_id,
                },
                updated_at: new Date().toISOString(),
              },
              {
                onConflict: 'name,user_id',
                returning: 'minimal',
              }
            );

            // Redirect to the integrations page
            navigate('/integrations');
          } catch (error) {
            console.error('Error handling Jira OAuth callback:', error);
            // Handle error (e.g., display a message to the user)
          }
        }

        handleJiraOAuthCallback();
      } else {
        // No recognizable parameters found
        console.error('No OAuth parameters found in URL');
        // Optionally, navigate to an error page or display a message
        navigate('/error');
      }
    }
  }, []);

  return null; // Render a loading spinner or message if desired
};

export default OAuthCallback;
