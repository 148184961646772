import React, { useEffect, useState, useCallback } from "react";
import Onboarding from "./Onboarding";
import { getSupabase } from "../utils/supabase";
import { useAuth } from "../context/AuthContext";
import OpenAI from "openai";


const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const supabase = getSupabase();

// date & greeting
let greeting = "";
let currentHour = new Date().getHours();

if (currentHour < 6) {
  greeting = "Good night";
} else if (currentHour < 12) {
  greeting = "Good morning";
} else if (currentHour < 19) {
  greeting = "Good afternoon";
} else {
  greeting = "Good evening";
}

const OnboardingChat = ({ onComplete }) => {

  const { user } = useAuth();

  const [chatLog, setChatLog] = useState([]);
  const [conversationEnded, setConversationEnded] = useState(false);
  const [userPrompt, setUserPrompt] = useState(null);
  const [showNextStepCue, setShowNextStepCue] = useState(false);


  useEffect(() => {
    if (!conversationEnded && chatLog.length > 0) {
      const aiMessages = chatLog.filter(
        (message) => message.role === "ai"
      ).length;
      const userMessages = chatLog.filter(
        (message) => message.role === "user"
      ).length;
      const lastMessage = chatLog[chatLog.length - 1];

      if (
        (lastMessage.role === "ai" &&
          lastMessage.content?.toLowerCase().includes("perfect" || "ready" || "interesting")) ||
        aiMessages >= 4 ||
        userMessages >= 4
      ) {
        setConversationEnded(true);
        handleEndConversation();
        setShowNextStepCue(true);
      }
    }
  }, [chatLog, conversationEnded]);

  // Start the onboarding conversation
  const startOnboardingConversation = useCallback(async () => {
    if (!user) {
      return; // Exit if no user
    }

    try {
      // Fetch user data from Supabase
      const { data: userData, error: fetchError } = await supabase
        .from("users")
        .select("user_prompt")
        .eq("id", user.id)
        .single();

      if (fetchError) throw fetchError;

      if (userData && userData.user_prompt) {
        setUserPrompt(
          `${greeting} ${user.user_metadata.full_name}!\n\nHere's what I know about you:\n\n${userData.user_prompt}\n\n\n**Does this seem correct?**`
        );
      } else {
        // If no user_prompt is found, set a default message
        setUserPrompt(
          `${greeting} ${user.user_metadata.full_name}!\n\nWelcome to Sindre! I'm here to help you with your product management needs.\n\nTo get started, could you tell me a bit about:\n\n1. Your role and experience in product management.\n2. The type of product or project you're working on.\n3. Any specific challenges or goals you have.\n\n**This information will help me tailor my assistance to your needs.**`
        );
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
      setUserPrompt("Error fetching user information");
    }
  }, [user]);

  useEffect(() => {
    if (user && !userPrompt) {
      startOnboardingConversation();
    }
  }, [user, userPrompt, startOnboardingConversation]);

  useEffect(() => {
    if (userPrompt) {
      setChatLog([{ role: "ai", content: userPrompt }]);
    }
  }, [userPrompt]);

  // end the onboarding conversation
  const handleEndConversation = async () => {
    const prompt = await makePrompt();
    await storePrompt(prompt);
    
  };

  const storePrompt = async (prompt) => {
    const { error } = await supabase
      .from("users")
      .update({
        user_prompt: prompt,
        default_user_prompt: prompt,
      })
      .eq("id", user.id);

    if (error) {
      console.error("Error storing prompt:", error);
    }
  };

  const makePrompt = async () => {
    const history = chatLog
      .map((message) => `${message.role}: ${message.content}`)
      .join("\n");

    const completion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: "You are a helpful assistant." },
        {
          role: "user",
          content: `Make a summary of this conversation as a description of the user : ${history}`,
        },
      ],
      model: "gpt-4o-mini",
    });

    const prompt = completion.choices[0].message.content;

    return prompt;
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg">
      <div className="flex-grow overflow-y-auto p-3 flex flex-col">
        <Onboarding
          chatLog={chatLog}
          setChatLog={setChatLog}
          userPrompt={userPrompt}
        />
      </div>

      {showNextStepCue && (
        <div className="fixed bottom-8 right-40 z-50">
          <button
            onClick={onComplete}
            className="bg-brandColor text-white px-6 py-3 rounded-full flex items-center shadow-lg hover:bg-brandColor-dark transition-all duration-300 transform hover:scale-105"
          >
            <span className="mr-2 font-bold">Continue to Next Step</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 animate-bounce" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default OnboardingChat;
