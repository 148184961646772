import React, { createContext, useContext, useState, useEffect } from 'react';


const BetaFeaturesContext = createContext();

export const BetaFeaturesProvider = ({ children }) => {

    const [betaFeatures, setBetaFeatures] = useState(() => {
        const savedFeatures = localStorage.getItem('betaFeatures');
        // Set default values to true if no saved preferences exist
        return savedFeatures ? JSON.parse(savedFeatures) : {
            nextBestPrompt: true,
            promptLibrary: true
        };
    });

    useEffect(() => {
        localStorage.setItem('betaFeatures', JSON.stringify(betaFeatures));
    }, [betaFeatures]);

    const toggleFeature = (feature) => {
        setBetaFeatures((prevFeatures) => ({
            ...prevFeatures,
            [feature]: !prevFeatures[feature]
        }));
    };

    return (
        <BetaFeaturesContext.Provider value={{ betaFeatures, toggleFeature }}>
            {children}
        </BetaFeaturesContext.Provider>
    );
};

export const useBetaFeatures = () => {
    const context = useContext(BetaFeaturesContext);
    if (!context) {
        throw new Error('useBetaFeatures must be used within a BetaFeaturesProvider');
    }
    return context;
};